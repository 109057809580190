import React from 'react';
// import { useGetAccountInfo, sendTransactions } from '@elrondnetwork/dapp-core';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { saleContractAddress } from 'config';
import { int2hex, str2hex } from 'helpers/encoding';

const BuyButton = ({priceTag}) => {
    const buttonDisplayValue = () => `${priceTag.displayPrice} $${priceTag.displayTicker}`;
    const {address} = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const handleBuyAction = async (e) => {
        e.preventDefault();

        if (!loggedIn) {
            window.location.href = '/connect'
            return;
        }
        if (priceTag.displayTicker === "EGLD"){
            await buyWithEgld();
        } else {
            await buyWithEsdt();
        }
    }

    const buyWithEgld = async () => {
        let transaction = {
            receiver: saleContractAddress,
            gasLimit: 10_000_000,
            data: `buy@${priceTag.saleId}`,
            value: priceTag.denominatedPrice
        };
        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: transaction,
            transactionsDisplayInfo: {
                processingMessage: 'Processing buy transaction',
                errorMessage: 'An error has occured during buying',
                successMessage: 'Transaction successful',
                transactionDuration: 10000,
            }
        });
    }

    const buyWithEsdt = async () => {
        let transaction = {
            receiver: saleContractAddress,
            gasLimit: 10_000_000,
            data: `ESDTTransfer@${str2hex(priceTag.ticker)}@${int2hex(parseInt(priceTag.denominatedPrice))}@${str2hex("buy")}@${priceTag.saleId}`,
            value: "0"
        };
        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: transaction,
            transactionsDisplayInfo: {
                processingMessage: 'Processing buy transaction',
                errorMessage: 'An error has occured during buying',
                successMessage: 'Transaction successful',
                transactionDuration: 10000,
            }
        });
    }

    return <div>
        <input type="button" className="btn btn-primary mt-2" value={buttonDisplayValue()} onClick={handleBuyAction}/>
    </div>
}

export default BuyButton;