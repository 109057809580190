/* eslint-disable */
import React, { Component, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import moment from 'moment'

import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { PLATA_TOKEN_TICKER } from 'config';
import { int2hex, str2hex } from 'helpers/encoding';

const Staking = ({ stakePool, plataBalance }) => {
    const [disabledTip, setdisabledTip] = useState('');
    const [tab, setTab] = useState('');
    const [userStake, setuserStake] = useState('');
    const handleUserStake = (e) => {
        //console.log( e.target.value )

        if (/^(\d+(\.\d{0,8})?|\.?\d{1,8})$/.test(e.target.value) || e.target.value == '') {
            setuserStake(e.target.value)
        }
    }

    const stake = async () => {

        if (userStake >= stakePool.minStakeAmount && userStake <= stakePool.maxStakeAmount) {
            let amount = parseFloat(userStake);

            amount = parseInt(amount?.toFixed(8).replace(".", ""));
            console.log(amount);
            console.log(int2hex(amount));
            //SEND
            const stakeTransaction = {
                value: "0",
                receiver: stakePool.scAddress,
                data: `ESDTTransfer@${str2hex(PLATA_TOKEN_TICKER)}@${int2hex(amount)}@${str2hex("stake")}`,
                gasLimit: 30_000_000
            };
            const { sessionId /*, error*/ } = await sendTransactions({
                transactions: stakeTransaction,
                transactionsDisplayInfo: {
                    processingMessage: 'Processing stake transaction',
                    errorMessage: 'An error has occured during staking',
                    successMessage: 'Transaction successful',
                    transactionDuration: 10000,
                }
            });
        } else {

            toast("Value needs to be between " + stakePool.minStakeAmount + " and " + stakePool.maxStakeAmount, {
                position: 'bottom-right',
                toastId: 'copy-address',
                transition: Slide,
                theme: 'dark'
                //className: 'foo-bar'
            });

        }

    }
    
    const claim = async () => {
        //SEND
        const claimTransaction = {
            value: "0",
            receiver: stakePool.scAddress,
            data: 'unstake',
            gasLimit: 80_000_000
        };
        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: claimTransaction,
            transactionsDisplayInfo: {
                processingMessage: 'Processing claim rewards transaction',
                errorMessage: 'An error has occured during claiming rewards',
                successMessage: 'Transaction successful',
                transactionDuration: 10000,
            }
        });
    }

    React.useEffect(() => {

        //console.log(plataBalance);
        //stakePool.claimableRewards = 1512532
        //stakePool.canUserClaim = true
        //console.log(stakePool);
        
        setdisabledTip( "Insufficient $PLATA amount/balance. Minimum: " + stakePool.minStakeAmount )

    }, []);

    //UI Needs:
    // stake button: hidden daca !stakePool.canUserStake; disabled daca user balance < minStakeAmount
    // claim button: hidden daca userul nu are nimic staked. disabled by default, enabled daca stakePool.canUserClaim == true

    return (<div className='container-fluid'>

        <div className="row text w-100" >



            <div className="col-6 col-md-3 p-1" >
                <div className="stats-box row m-0 stats-box-small" >
                    <div className="col-12 mt-2" >
                        <div className="text-center text-bold text-700 "> APR </div>
                        <div className="heading4" > {stakePool.APR}% </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-3 p-1" >
                <div className="stats-box row m-0 stats-box-small" >
                    <div className="col-12 mt-2" >
                        <div className="text-center text-bold text-700 "> Pool capacity </div>
                        <div className="heading4" > {stakePool.poolCapacity} </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-3 p-1" >
                <div className="stats-box row m-0 stats-box-small" >
                    <div className="col-12 mt-2" >
                        <div className="text-center text-bold text-700 ">  Total staked in pool </div>
                        <div className="heading4" > {stakePool.totalStaked} </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-3 p-1" >
                <div className="stats-box row m-0 stats-box-small" >
                    <div className="col-12 mt-2" >
                        <div className="text-center text-bold text-700 ">  Lock period </div>
                        <div className="heading4" > {stakePool.lockTimePeriodDays} Days</div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-3 p-1" >
                <div className="stats-box row m-0 stats-box-small" >
                    <div className="col-12 mt-2" >
                        <div className="text-center text-bold text-700 ">  Min Amount </div>
                        <div className="heading4" > {stakePool.minStakeAmount} $PLATA </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-md-3 p-1" >
                <div className="stats-box row m-0 stats-box-small" >
                    <div className="col-12 mt-2" >
                        <div className="text-center text-bold text-700 ">  Max Amount </div>
                        <div className="heading4" > {stakePool.maxStakeAmount} $PLATA </div>
                    </div>
                </div>
            </div>
            {/* <div className="col-6 col-md-3 p-1" >
                <div className="stats-box row m-0 stats-box-small" >
                    <div className="col-12 mt-2" >
                        <div className="text-center text-bold text-700 ">  Lock period </div>
                        <div className="heading4" > {stakePool.lockTimePeriodDays} Days</div>
                    </div>
                </div>
            </div> */}

            {stakePool.totalStakedByUser > 0 &&
                <>
                    <div className="col-6 col-md-3 p-1" >
                        <div className="stats-box row m-0 stats-box-small" >
                            <div className="col-12 mt-2" >
                                <div className="text-center text-bold text-700 ">  Total staked by you </div>
                                <div className="heading4" > {stakePool.totalStakedByUser} </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-md-3 p-1" >
                        <div className="stats-box row m-0 stats-box-small" >
                            <div className="col-12 mt-2" >
                                <div className="text-center text-bold text-700 ">  Total earned </div>
                                <div className="heading4" > {stakePool.totalEarned} </div>
                            </div>
                        </div>
                    </div>
                </>
            }


            <div className="col-12 col-md-6 mt-3 p-4" >
                
                { stakePool.claimableRewards > 0 && 
                <span className="stats-box p-3 mr-4" >
                    <span className="text" > Rewards: </span>  {stakePool.claimableRewards} 
                </span>    
                }
            
                {stakePool.totalStakedByUser > 0 && stakePool.canUserClaim == false &&
                    <a className="mybtn btn-black pt-2 pb-2 mr-2 d-inline disabled" disabled data-tip={"Claimable on " + moment(stakePool.unstakeTime).format('YYYY-MM-DD HH:mm')} > Claim </a>
                }
                                
                {stakePool.totalStakedByUser > 0 && stakePool.canUserClaim == true &&
                    <a onClick={() => { claim() }} className="mybtn btn-black pt-2 pb-2 mr-2 d-inline" > Claim </a>
                }

            </div>

            <div className="col-12 col-md-6 p-4 text-md-right" >

                {/* {stakePool.canUserStake &&
                    <input className="form-control d-inline mt-3 w-md-auto mr-4" value={userStake} onChange={handleUserStake} placeholder="0" />
                }

                {stakePool.canUserStake && plataBalance < stakePool.minStakeAmount &&
                    <a data-tip={disabledTip} className="mybtn btn-black pt-2 pb-2 mr-2 d-inline disabled" disabled  > Stake </a>
                }
                {stakePool.canUserStake && plataBalance >= stakePool.minStakeAmount &&
                    <a onClick={() => { stake() }} className="mybtn btn-black pt-2 pb-2 mr-2 d-inline "  > Stake </a>
                } */}

                <ReactTooltip />
                <ToastContainer transition={Slide} />
                    
            </div>

            

        </div>

    </div>
    );
};

export default Staking;
