/* eslint-disable */
import React, { Component, useState } from 'react';
import WalletItem from '../../components/Wallet/walletItem';

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

//let DRIVERS = require('../../db/drivers.json')
//let CARS = require('../../db/cars.json')
//let LEGENDS = require('../../db/legends.json')

import DRIVERS from '../../db/drivers.json';
import CARS from '../../db/cars.json';
import LEGENDS from '../../db/legends.json';
import NFTTab from './NFTTab';
import { getWalletNFTs } from 'apiRequests';
import { saleContractAddress } from 'config';

const Token = () => {
    const [mounted, setMounted] = useState(false);
    const [tab, setTab] = useState('drivers');
    const [drivers, setDrivers] = useState([]);
    const [cars, setCars] = useState([]);
    const [legends, setLegends] = useState([]);
    
    const [data, setData] = useState([]);
    
    const changeTab = (tab) => {
        setTab(tab)
        setData([])
        if( tab == 'drivers' ){ setData(DRIVERS) }
        if( tab == 'cars' ){ setData(CARS) }
        if( tab == 'legends' ){ setData(LEGENDS) }
    }
    
    React.useEffect(() => {

        //console.log('nft');
        // setDrivers(DRIVERS);
        // setCars(CARS);
        setLegends(LEGENDS);
        
        changeTab('drivers')
        getWalletNFTs({address: saleContractAddress})
            .then(resp => {
                let esdts = resp.data.data.esdts;
                setDrivers(updateSupply(DRIVERS, esdts));
                setCars(updateSupply(CARS, esdts));
                setLegends(updateSupply(LEGENDS, esdts));
                setMounted(true);
            })
    }, []);

    const updateSupply = (arr, esdts) => {
        let co = [];
        for(var i = 0; i < arr.length; i++){
            if(arr[i].status === "available"){
                let obj = arr[i];
                if(esdts[arr[i].fullTokenIdentifier] !== undefined)
                    obj.supply = esdts[arr[i].fullTokenIdentifier].balance;
                co.push(obj);
            } else {
                co.push(arr[i]);
            }
        }

        return co;
    }

    return mounted && (
    <>
        {/* <div className="">
            <div className="container">
                <div className="row ">

                    <div className="col-6 offset-3 col-md-4 offset-md-4 text-center">
                        <img id="" src="./home-car-final.png" className="ml-auto slider-image img-mirror" />
                    </div>

                </div> 
            </div>
        </div> */}
        <div className='container'>

            <div className="row mt-5 text" >

                {/* <div className="col-12 mb-3" >
                    We publish NFTs exclusively on the Elrond blockchain. Highly detailed hand drawn artworks representing drivers, racing cars and motor sports related content, are available on our platforms, and also on the Elrond marketplaces.
                </div> */}

                <div className="col-12 text-center mt-5" >



                    <div className="col-12 col-md-8 offset-md-2 mt-3 mb-5" >
                        <div className="row" >
                            <div className="col-4 " > <img src="./drivers-01.jpg" alt="drivers" onClick={()=>{ changeTab('drivers') }} className="cursor-pointer axonometric-right" /> </div>    
                            
                            <div className="col-4" > <img src="./nfts/legends.jpg" alt="legends" onClick={()=>{ changeTab('legends') }} className="cursor-pointer axonometric-right" /> </div>    

                            <div className="col-4" > <img src="./nfts/cars.jpg" alt="cars" onClick={()=>{ changeTab('cars') }} className="cursor-pointer axonometric-right" /> </div>    
                        </div>
                    </div>

                    { tab == 'drivers' && 
                        <NFTTab nfts={drivers}/>
                    }

                    { tab == 'cars' && 
                        <NFTTab nfts={cars} />
                    }

                    { tab == 'legends' && 
                        <NFTTab nfts={legends} />
                    }

                </div>

                <div className="col-12 text-center mt-3 d-none" >
                    <Tabs defaultActiveKey="drivers" id="nav-tab" className="w-auto mt-4 mb-3">
                        <Tab eventKey="drivers" title="Drivers">

                            <div className="row no-gutters mb-4">
                                <div className="col-12 items row" >
                                    { drivers.map((item, i) =>{
                                        if( item.status == 'available' ){ return ( <WalletItem key={i} v={item} properties={ {"clickable": false} } /> ); }
                                    })}
                                </div>

                                <div className="w-100 horizontal-line mt-5 mb-5"></div>

                                <div className="col-12 items row" >
                                    { drivers.map((item, i) =>{
                                        if( item.status == 'unavailable' ){ return ( <WalletItem key={i} v={item} properties={ {"clickable": false} } /> ); }
                                    })}
                                </div>
                            </div>

                        </Tab>

                        <Tab eventKey="cars" title="Cars">

                            <div className="row no-gutters mb-4">
                                <div className="col-12 items row" >
                                    { cars.map((item, i) =>{
                                        if( item.status == 'available' ){ return ( <WalletItem key={i} v={item} properties={ {"clickable": false} } /> ); }
                                    })}
                                </div>

                                <div className="w-100 horizontal-line mt-5 mb-5"></div>

                                <div className="col-12 items row" >
                                    { cars.map((item, i) =>{
                                        if( item.status == 'unavailable' ){ return ( <WalletItem key={i} v={item} properties={ {"clickable": false} } /> ); }
                                    })}
                                </div>
                            </div>

                        </Tab>

                        <Tab eventKey="legends" title="Legends" >

                            <div className="row no-gutters mb-4">
                                <div className="col-12 items row" >
                                    { legends.map((item, i) =>{
                                        if( item.status == 'available' ){ return ( <WalletItem key={i} v={item} properties={ {"clickable": false} } /> ); }
                                    })}
                                </div>

                                <div className="w-100 horizontal-line mt-5 mb-5"></div>

                                <div className="col-12 items row" >
                                    { legends.map((item, i) =>{
                                        if( item.status == 'unavailable' ){ return ( <WalletItem key={i} v={item} properties={ {"clickable": false} } /> ); }
                                    })}
                                </div>
                            </div>

                        </Tab>

                    </Tabs>
                </div>

            </div>

        </div>
    </>
    );
};

export default Token;
