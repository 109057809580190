/* eslint-disable */
import React, { useState } from 'react';
import { useGetIsLoggedIn, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import LockedStaking from './Staking/LockedStaking';
import NFTStakingComp from './Staking/NftStaking';
import Wallet from '../Wallet';
import Power from 'components/Power';
import { carPacksSwapAddress, lockedStakingPools, PLATA_TOKEN_TICKER } from 'config';
import { getLockedStakingData } from 'helpers/lockedStaking';
import { getWalletNFTs } from 'helpers/asyncRequests';
import { getNftStakingData } from 'helpers/nftStaking';
import { str2hex, int2hex, decodeAddress } from 'helpers/encoding';

const Stake = () => {
    const [mounted, setMounted] = useState(false);
    const [plataBalance, setPlataBalance] = useState(0);
    const [walletEsdts, setWalletEsdts] = useState();
    const [stakingData, setStakingData] = useState([]);
    const [NFTStaking, setNFTStakingData] = useState([]);
    const [fullSetCarPack1, setFullSetCarPack1] = useState([]);
    const [fullSetCarPack2, setFullSetCarPack2] = useState([]);
    const carPack1Tickers = ["PLTCAR001-704035", "PLTCAR002-704035", "PLTCAR003-704035", "PLTCAR004-704035", "PLTCAR005-704035", "PLTCAR006-704035", "PLTCAR007-704035", "PLTCAR008-704035", "PLTCAR009-704035", "PLTCAR010-704035", "PLTCAR011-704035", "PLTCAR012-704035", "PLTCAR013-704035", "PLTCAR014-704035", "PLTCAR015-704035", "PLTCAR016-704035", "PLTCAR017-704035", "PLTCAR018-704035", "PLTCAR019-704035", "PLTCAR020-704035", "PLTCAR021-704035", "PLTCAR022-704035", "PLTCAR023-704035", "PLTCAR024-704035", "PLTCAR025-81e54b"];
    const carPack2Tickers = ["PLTCAR026-81e54b", "PLTCAR027-81e54b", "PLTCAR028-81e54b", "PLTCAR029-81e54b", "PLTCAR030-81e54b", "PLTCAR031-81e54b", "PLTCAR032-81e54b", "PLTCAR033-81e54b", "PLTCAR034-81e54b", "PLTCAR035-81e54b", "PLTCAR036-81e54b", "PLTCAR037-81e54b", "PLTCAR038-81e54b", "PLTCAR039-81e54b", "PLTCAR040-81e54b", "PLTCAR041-81e54b", "PLTCAR042-81e54b", "PLTCAR043-81e54b", "PLTCAR044-81e54b", "PLTCAR045-81e54b", "PLTCAR046-81e54b", "PLTCAR047-81e54b", "PLTCAR048-81e54b", "PLTCAR049-81e54b", "PLTCAR050-e8a825"];
    const { address } = useGetAccountInfo();
    // const address = 'erd1g3ufcfdlnpamunzpaa4kutg3z7y9vtuqm3twpkh57ye2ppycgenseekdcl';

    // const address = "erd1pq7ehcrvkwnlkefssmsr4th93rwa5dqpul0pe85j7kpxwxve8wnqr4cm7t";
    // const address = "erd15hzdgp48hcfjakza9ge5kj9uvwjpad3ujhuvrlh9u9mvegalp8vskphapp";
    // const address = "erd1l8qmksyvfgn60hqhqgucufzx5adp4kny8gf07vypmgytyxqcgr9q3u6jnk";
    // const address = "erd1g3ufcfdlnpamunzpaa4kutg3z7y9vtuqm3twpkh57ye2ppycgenseekdcl";
    // const address = "erd1r3tntrdkmlsrm0wawhc3zy2d6dvdgrda5mpaxvs89s4u6sht30gqswjz67";
    // const address = "erd1g3ufcfdlnpamunzpaa4kutg3z7y9vtuqm3twpkh57ye2ppycgenseekdcl";
    // const address = "erd1qqqqqqqqqqqqqpgq4dj38y83lr6qjy078h6rq8f8vfwt5rr0gr9qu8qgmk";

    const setLockedStakingData = (plataBalance) => {
        let promises = [];
        for (var i = 0; i < lockedStakingPools.length; i++) {
            let poolSettings = lockedStakingPools[i];
            promises.push(getLockedStakingData(poolSettings.scAddress, address, poolSettings.poolName, poolSettings.poolCapacity, poolSettings.minStakeAmount, poolSettings.maxStakeAmount, poolSettings.apr, poolSettings.lockTimePeriodDays));
        }
        Promise.all(promises)
            .then(poolSettings => {
                // console.log(poolSettings);
                setStakingData(poolSettings);
            })
    }

    const isLoggedIn = useGetIsLoggedIn();
    React.useEffect(() => {
        if (!isLoggedIn) {
            window.location.href = '/connect';
        } else {
            Promise.all([
                getWalletNFTs({ address }),
                getNftStakingData(address),
            ]).then(([esdts, nftStakingData]) => {
                //esdts.data.data.esdts.forEach((item)=>{
                //    console.log(item)
                //})
                computeCarPacksInfo(esdts.data.data.esdts);
                setWalletEsdts(esdts.data.data.esdts);
                setPlataBalance(denominatePlata(esdts.data.data.esdts[PLATA_TOKEN_TICKER]?.balance ?? 0));
                setLockedStakingData();
                setNFTStakingData(nftStakingData)
                setMounted(true);
            });
        }
    }, []);

    const computeCarPacksInfo = (esdts) => {
        let esdtKeys = Object.keys(esdts);
        let hasFullCarPack1 = true;
        let hasFullCarPack2 = true;

        let carPack1Items = [];
        let carPack2Items = [];
        for (let i = 0; i < carPack1Tickers.length && hasFullCarPack1; i++) {
            let carPack1Item = esdtKeys.filter(cp => cp.startsWith(carPack1Tickers[i]));
            if (carPack1Item.length === 0) {
                hasFullCarPack1 = false;
            }
            carPack1Items.push(carPack1Item[0]);
        }

        for (let i = 0; i < carPack2Tickers.length && hasFullCarPack2; i++) {
            let carPack2Item = esdtKeys.filter(cp => cp.startsWith(carPack2Tickers[i]));
            if (carPack2Item.length === 0) {
                hasFullCarPack2 = false;
            }
            carPack2Items.push(carPack2Item[0]);
        }
        if (hasFullCarPack1) {
            setFullSetCarPack1(carPack1Items);
        }
        if (hasFullCarPack2) {
            setFullSetCarPack2(carPack2Items);
        }
    }

    const handleCarPackSwapTransaction = async (carPackNo) => {
        let carPackToUse = carPackNo === 1 
            ? fullSetCarPack1 : fullSetCarPack2;
        if (carPackToUse.length === 25) {
            let baseGasLimit = 15_000_000;
            const gasPerNft = 2_000_000;
            let transaction = {
                receiver: address,
                gasLimit: baseGasLimit,
                data: "",
                value: "0"
            };

            transaction.data = `MultiESDTNFTTransfer@${decodeAddress(carPacksSwapAddress)}@${int2hex(carPackToUse.length)}`;
                for (var i = 0; i < carPackToUse.length; i++) {
                    let spl = carPackToUse[i].split('-');
                    let tokenIdentifier = `${spl[0]}-${spl[1]}`;
                    let nonce = spl[2];
                    transaction.data +=
                        `@${str2hex(tokenIdentifier)}@${nonce}@01`;
                    transaction.gasLimit += gasPerNft;
                }
                transaction.data += `@${str2hex(`swap`)}@0${carPackNo + 1}`;
            const { sessionId, error } = await sendTransactions({
                transactions: [transaction],
                transactionsDisplayInfo: {
                    processingMessage: 'Processing NFT Stake transaction',
                    errorMessage: 'An error has occured during staking',
                    successMessage: 'Transaction successful',
                    transactionDuration: 10000,
                }
            });
        } else {
            toast('No full car pack found on your wallet', {
                position: 'bottom-right',
                toastId: 'copy-address',
                transition: Slide,
                theme: 'dark'
                //className: 'foo-bar'
            });
        }
    }

    const denominatePlata = (plataBal) => {
        if (plataBal === 0)
            return 0;
        return plataBal / 100_000_000;
    }

    return !mounted ? null : (
        <div className=' dashboard'>

            <Power />

            <div className="mt-5" >

                {fullSetCarPack1.length > 0 && <>
                    <div className="container" >
                        <div className="col-12 mb-2" >
                            <div className="row" >
                                <div className="col-12" >
                                    <h1 className="text-left text-bold text-700 mt-2 heading4 font-primary"> Car pack 1 swap </h1>
                                </div>
                            </div>
                            <div className="row mb-5" >
                                <div className="col-12 col-lg-6" >
                                    <h6 className="text-left text-bold mt-2 mb-5 heading4 font-primary" style={{fontSize: "small"}}> Trade your car pack 1 for an exclusive V8 PLATA NFT</h6>
                                </div>
                                <div className="col-12 col-lg-6" >
                                <a className="mybtn btn-black pt-2 pb-2 mr-2 d-inline w-auto" onClick={() => { handleCarPackSwapTransaction(1) }} > Swap Car Pack 1 </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 horizontal-line mb-5"></div>
                </>}

                {fullSetCarPack2.length > 0 && <>
                    <div className="container" >
                        <div className="col-12 mb-2" >
                            <div className="row" >
                                <div className="col-12" >
                                    <h1 className="text-left text-bold text-700 mt-2 heading4 font-primary"> Car pack 2 swap </h1>
                                </div>
                            </div>
                            <div className="row mb-5" >
                                <div className="col-12 col-lg-6" >
                                    <h6 className="text-left text-bold mt-2 mb-5 heading4 font-primary" style={{fontSize: "small"}}> Trade your car pack 2 for an exclusive V8 PLATA NFT</h6>
                                </div>
                                <div className="col-12 col-lg-6" >
                                <a className="mybtn btn-black pt-2 pb-2 mr-2 d-inline w-auto" onClick={() => { handleCarPackSwapTransaction(2) }} > Swap Car Pack 2 </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-100 horizontal-line mb-5"></div>
                </>}

                <div className="container" >
                    <div className="col-12 mb-2" >
                        <div className="row" >
                            <div className="col-12" >
                                <h1 className="text-left text-bold text-700 mt-2 heading4 font-primary"> Staking </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-100 horizontal-line"></div>


                <div className="container " >
                    <div className="col-12 mt-5 " >
                        <Accordion>

                            {stakingData.map((stake, i) => {
                                return (
                                    <Accordion.Item eventKey={i} key={i}>
                                        <Accordion.Header className='border-0'>
                                            <div className="row w-100 mr-3" >
                                                <div className="col text-left" > {stake.poolName} </div>
                                                <div className="col-auto text-right" > <span className="float-right" > {stake.APR}% </span > </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>

                                            <LockedStaking stakePool={stake} plataBalance={plataBalance} />

                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })}

                            {walletEsdts && <Accordion.Item eventKey={stakingData.length + 1}>
                                <Accordion.Header>
                                    <div className="row w-100 mr-3" >
                                        <div className="col text-left" > {NFTStaking.poolName} </div>
                                        <div className="col-auto text-right" > <span className="float-right" > <b>{NFTStaking.rewardsPerDay} $PLATA</b>/day </span > </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>

                                    <NFTStakingComp stakePool={NFTStaking} esdtData={walletEsdts} />

                                </Accordion.Body>
                            </Accordion.Item>}

                            <Accordion.Item eventKey={stakingData.length + 2}>
                                <Accordion.Header>
                                    <div className="row w-100 mr-3" >
                                        <div className="col text-left" > PLATA Standard Staking </div>
                                        <div className="col-auto text-right" > <span className="float-right" > </span > </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className='d-flex justify-content-center align-items-center mt-3'>
                                        <div>
                                        Standard staking

<br />
<br />

<a href="https://stake.plata.network" className="mybtn btn-black pt-2 pb-2 mr-2 mb-5" > Stake </a>

<br />
<br />
                                        </div>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>

                        </Accordion>
                    </div>
                </div>

            </div>

            {/* <div className="mt-5" >

                <div className="container" >
                    <div className="col-12 mb-2" >
                        <div className="row" >
                            <div className="col-12 col-md" >
                                <h1 className="text-left text-bold text-700 mt-2 heading4 font-primary"> Wallet </h1>
                            </div>
                            <div className="col-12 col-md-auto text-right d-none" >
                                <Link to="/wallet" > See Wallet </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-100 horizontal-line"></div>

                <div className="col-12 container" >

                    {walletEsdts && <Wallet details={{ source: "component" }} walletInventoryData={walletEsdts} />}

                </div>

            </div> */}

        </div>
    );
};

export default Stake;
