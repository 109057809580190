import WalletItem from '../../components/Wallet/walletItem';

const NFTTab = ({nfts}) => {
    return <div className="col-12 mt-2" >

        <div className="row no-gutters mb-4">
            <div className="col-12 items row" >
                {nfts.map((item, i) => {
                    item.url = item.uris[0]
                    if (item.status == 'available') { return (<WalletItem key={i} v={item} properties={item} />); }
                })}
            </div>

            <div className="w-100 horizontal-line mt-5 mb-5"></div>

            <div className="col-12 items row" >
                {nfts.map((item, i) => {
                    item.url = item.uris[0]
                    if (item.status == 'unavailable') { return (<WalletItem key={i} v={item} properties={{ "clickable": false }} />); }
                })}
            </div>
        </div>

    </div>;
}

export default NFTTab;