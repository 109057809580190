import axios from 'axios';
import * as config from '../config';

/*
interface GetCollectionsType {
    address: string;
}
*/

const fetchWalletNFTs = () =>
    async function getWalletNFTs({ address }) {
        try {
            const { data } = await axios.get(
                `${config.network.gatewayAddress}/address/${address}/esdt`
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            console.log('failed on fetching wallet nfts 2');
            return {
                success: false,
            };
        }
    };

const fetchWalletNftsApi = (collection) =>
    async function getWalletNFTsApi({ address }) {
        try {
            const { data } = await axios.get(
                `${config.network.apiAddress}/accounts/${address}/nfts?size=10000&collection=${collection}`
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            console.log('failed on fetching wallet nfts api');
            return {
                success: false,
            };
        }
    };

export const getWalletNFTs = fetchWalletNFTs();
export const getDriversNFTs = fetchWalletNftsApi('DRIVERS-f247ce');
