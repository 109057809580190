import { 
    Address,
    AddressValue, 
    EndpointDefinition,
    EndpointModifiers,
    EndpointParameterDefinition,
    TypeExpressionParser,
    TypeMapper,
    ResultsParser
} from "@multiversx/sdk-core/out";
import * as config from "../config";
import { fetchQueryHex, fetchQueryResponse } from "./scRequests";
const typeParser = new TypeExpressionParser();
const typeMapper = new TypeMapper();


export const getNftStakingData = (userAddress) =>
    Promise.all([
        fetchQueryResponse("user", [new AddressValue(new Address(userAddress))], config.nftStaking.scAddress),
        fetchQueryHex("isPublicUseDisabled", [], config.nftStaking.scAddress)
    ]).then(([qResponse, publicUseDisabled]) => {
        let finalObj = undefined;
        if (qResponse.returnCode === 'ok') {
            let resultsParser = new ResultsParser();
            let parsedBundle = resultsParser.parseQueryResponse(qResponse, endpointDefinitions.nftStaking());
            let parsed = parsedBundle.firstValue.valueOf();
            finalObj = {
                poolName: config.nftStaking.poolName,
                claimableRewards: parseNumericalValue(parsed.field0.valueOf()),
                rewardsPerDay: parseNumericalValue(parsed.field1.valueOf()),
                stakedNFTs: parseStakedNfts(parsed.field2),
                isDisabled: (publicUseDisabled === "01")
            }
            // console.log("Stake times: ");
            // console.log(finalObj.stakedNFTs.map(s => s.claimMoment + "|||" + s.tokenIdentifier + " " + s.nonce));

            // Promise.resolve(finalObj);
        }
        return finalObj;
    })


const endpointDefinitions = {
    nftStaking: () => {
        let types = ["tuple3<BigUint, BigUint, List<tuple3<TokenIdentifier, u64, BigUint>>>"]
            .map((expression) => typeParser.parse(expression))
            .map((type) => typeMapper.mapType(type));
        let endpointDefinitions = types.map((type) => new EndpointParameterDefinition("foo", "bar", type));
        let endpointDef = new EndpointDefinition("user", [], endpointDefinitions, new EndpointModifiers("", []));
        return endpointDef;
    }
}

const parseNumericalValue = (val) => val == 0 ? 0 : parseFloat((val / 100_000_000)?.toFixed(3));

const parseStakedNfts = (val) => {
    if (val.length === 0) {
        return [];
    }
    let arr = [];
    for (var i = 0; i < val.length; i++) {
        let crt = val[i];
        let nftData = {
            tokenIdentifier: Buffer.from(crt.field0.valueOf()).toString(),
            nonce: parseInt(crt.field1.valueOf().toString()),
            quantity: parseInt(crt.field2.valueOf().toString()),
            // dailyReward: parseInt(crt.fields[3].value.valueOf().toString()) / 100_000_000,
        }
        arr.push(nftData);
    }
    return arr;
}