/* eslint-disable */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as HeartIcon } from '../../../assets/img/heart.svg';

const Footer = () => {
  return (
    <footer className="color-white text-center">
        
        <div className="container mt-2">
            <div className="row mb-5 mt-5">

                <div className="col-6 col-md-3">
                    <div className="widget">
                        <h3 className="text-500 text font-primary d-none"> Support </h3>
                        <ul className="footer-navigation list-unstyled color-gray-light">
                            <li className="menu-item">
                                <a href="https://plata.network/whitepaper" rel="noreferrer" target="_blank" className="animated-border-bottom"> Whitepaper </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-6 col-md-3">
                    <div className="widget">
                        <h3 className="text-500 text font-primary d-none"> Support </h3>
                        <ul className="footer-navigation list-unstyled color-gray-light">
                            <li className="menu-item">
                                <a href="https://plata.network/manifesto" rel="noreferrer" target="_blank" className="animated-border-bottom"> Manifesto </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="col-6 col-md-3">
                    <div className="widget">
                        <h3 className="text-500 text font-primary d-none"> Support </h3>
                        <ul className="footer-navigation list-unstyled color-gray-light">
                            <li className="menu-item">
                                <a href="https://linktr.ee/platanetwork" target="_blank" rel="noreferrer" className="animated-border-bottom"> Official Links </a>
                            </li>
                        </ul>
                    </div>
                </div>


                <div className="col-6 col-md-3">
                    <div className="widget">
                        <h3 className="text-500 text font-primary d-none"> Support </h3>
                        <ul className="footer-navigation list-unstyled color-gray-light">
                            <li className="menu-item">
                                <a href="https://plata.network/disclaimer" target="_blank" rel="noreferrer" className="animated-border-bottom"> Disclaimer </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

        <div className="w-100 horizontal-line"></div>

        <div className="container">
            <div className='row'>
                <div className='col d-flex justify-content-center mt-3'>
                <iframe src="https://egld.community/api/products/146181f8-bd27-4526-8896-7355f008baa3/upvotes/embed" width="290" height="70" style={{borderRadius: '8px', border: 'none'}}></iframe>
                </div>
            </div>
            <div className="row justify-content-md-between mt-0 pt-3 pb-4 ">

                    <div className="col-md-8 d-inline-flex align-items-center justify-content-center justify-content-md-start">
                        <span className="main-footer__copyrights text-gray d-block text-small mr-0"> Made with ❤️ by Plata Network</span>
                    </div>

                    <div className="col-md-4 col-lg-auto c-mt-6 c-mt-md-0">
                        <ul className="social-icons list-unstyled d-flex justify-content-center justify-content-md-end mt-3">
                            
                            <li className="">
                                <a href="https://twitter.com/platanetwork" className="social-link d-flex align-items-center justify-content-center" target="_blank" rel="noreferrer" >
                                    <i className="fab fa-twitter" ></i>
                                </a>
                            </li>
                            <li className="ml-4">
                                <a href="https://t.me/plataNetwork" className="social-link d-flex align-items-center justify-content-center" target="_blank" rel="noreferrer" >
                                    <i className="fab fa-telegram" ></i>
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>

        </div>
    </footer>
  );
};

export default Footer;
