//TODO: port calculation from the other repo
//TODO: accept esdts and staked nfts, and merge them together
//TODO: as a quick fix hack for not having NFT's attributes, you can
//      search on the explorer what attributes each nft has
//      and hardcoding the data
const _250PowerTickers = [
    'RACING-fc7ad4-02',
    'RACING-fc7ad4-01',
    'PLATA-716c04-e9',
    'PLATAVOX-c763d7-01',
    'PLATA-716c04-e2',
    'PLATA-716c04-e1',
    'PLATA-716c04-dd',
    'PLATA-716c04-d4',
    'PLATA-716c04-ce',
    'PLATA-716c04-8c',
    'PLATA-716c04-8b',
    'PLATA-716c04-0e',
    'PLATA-716c04-01',
    'GCCMMXXI-12efe5-01'
];

const computePlataPower = (esdts, stakingData, driversData) => {
    var score = {
        army: 0,
        stamina: 0,
        bhp: 0,
        cc: 0,
        speed: 0,
        tracks: 0,
        gcc: 0,
        plata: 0,
        special: 0,
        emoon: 0,

        balance: 0,
        imgPath: "", title: "Pilot",
        level: 0
    };
    for (const [key, value] of Object.entries(esdts)) {
        //const parsedValue = value as DecodedNFTDetailsType;
        const parsedValue = value;

        const identifierSplit = key.split('-');
        parsedValue.identifier = identifierSplit[0] + '-' + identifierSplit[1];
        if (parsedValue.identifier.includes('GCC-3194ab')) {
            score.gcc = 100;
        }
        if (parsedValue.uris === undefined) {
            getPlataPowerESDTS(parsedValue, score);
            continue;
        }

        parsedValue.attributes = atob(parsedValue.attributes || '');
        for (let i = 0; i < parsedValue.uris.length; i++) {
            parsedValue.uris[i] = atob(parsedValue.uris[i]);
        }
        setNFTPlataPower(parsedValue, score);
    }

    updateScoresWithDriversData(driversData, score);
    updateScoresWithStakingData(stakingData, score);
    score.total = score.army + score.stamina + score.bhp
        + score.cc + score.speed + score.tracks + score.gcc +
        score.plata + score.special + score.emoon;

    let rankData = getRankData(score.total);
    score.level = rankData.level;
    score.imgPath = rankData.imgPath;
    score.title = rankData.title;

    return score;
}

const setNFTPlataPower = (nftData, score) => {
    if (_250PowerTickers.includes(nftData.tokenIdentifier)) {
        score.special += 250;
        score.army += parseInt(nftData.balance);
        return;
    }
    switch (nftData.identifier) {
        case 'PLATAXMAS-874bd3':
        case 'CYBERPLATA-101cb3':
            score.army += 1;
            break;

        case 'PLATA-716c04':
        case 'GCCMMXXI-12efe5':
        case 'DRIVER-58558e':
        case 'BSKDRIVER-3a1600':
        case 'ESDTRUSH-4515bf':
        case 'PLATAXCIA-4ea660':
            score.emoon += 100;
            score.army += parseInt(nftData.balance);
            break;

        case 'PLTPILOT-f3ac90':
        case 'PLTRIDER-f3ac90':
        case 'PLTAIDRV-f3ac90':
        case 'PLTSTART-f3ac90':
            score.army += parseInt(nftData.balance);
            break;

        case 'RACING-fc7ad4': //Year:2021; Origin:UK; Engine(cc): 4000; Type: V8; Power(bhp): 528; TopSpeed(km/h): 314;
        case 'PLTCAR001-704035':
        case 'PLTCAR002-704035':
        case 'PLTCAR003-704035':
        case 'PLTCAR004-704035':
        case 'PLTCAR005-704035':
        case 'PLTCAR006-704035':
        case 'PLTCAR007-704035':
        case 'PLTCAR008-704035':
        case 'PLTCAR009-704035':
        case 'PLTCAR010-704035':
        case 'PLTCAR011-704035':
        case 'PLTCAR012-704035':
        case 'PLTCAR013-704035':
        case 'PLTCAR014-704035':
        case 'PLTCAR015-704035':
        case 'PLTCAR016-704035':
        case 'PLTCAR017-704035':
        case 'PLTCAR018-704035':
        case 'PLTCAR019-704035':
        case 'PLTCAR020-704035':
        case 'PLTCAR021-704035':
        case 'PLTCAR022-704035':
        case 'PLTCAR023-704035':
        case 'PLTCAR024-704035':
        case 'PLTCAR025-81e54b':
        case 'PLTCAR026-81e54b':
        case 'PLTCAR027-81e54b':
        case 'PLTCAR028-81e54b':
        case 'PLTCAR029-81e54b':
        case 'PLTCAR030-81e54b':
        case 'PLTCAR031-81e54b':
        case 'PLTCAR032-81e54b':
        case 'PLTCAR033-81e54b':
        case 'PLTCAR034-81e54b':
        case 'PLTCAR035-81e54b':
        case 'PLTCAR036-81e54b':
        case 'PLTCAR037-81e54b':
        case 'PLTCAR038-81e54b':
        case 'PLTCAR039-81e54b':
        case 'PLTCAR040-81e54b':
        case 'PLTCAR041-81e54b':
        case 'PLTCAR042-81e54b':
        case 'PLTCAR043-81e54b':
        case 'PLTCAR044-81e54b':
        case 'PLTCAR045-81e54b':
        case 'PLTCAR046-81e54b':
        case 'PLTCAR047-81e54b':
        case 'PLTCAR048-81e54b':
        case 'PLTCAR049-81e54b':
        case 'PLTCAR050-e8a825':
        case 'RACING-c0396f':
        case 'RACING-fbe2cc':
        case 'RACING-61cd12':
        case 'V8PLATA-e11632':
            getPlataPowerByAttributes(nftData.attributes, score, parseInt(nftData.balance));
            score.army += parseInt(nftData.balance);
            break;
        case 'XDRIVER-2ce741':
            getPlataPowerByAttributes(nftData.attributes, score, parseInt(nftData.balance));
            score.army += parseInt(nftData.balance);
            score.emoon += 100;
            break;
        case 'ALLTEAM-374106':
            getPlataPowerByAttributes(nftData.attributes, score, parseInt(nftData.balance));
            score.army += parseInt(nftData.balance);
            break;
        case 'DRIVERS-f247ce':
            // added separately
            score.army += parseInt(nftData.balance);
            break;
        default:
            return;
    }
};

const getPlataPowerByAttributes = (attributes, score, balance) => {
    if (attributes === undefined || typeof (attributes) !== typeof ("")) {
        return;
    }
    let attributeList = attributes.split(';');
    for (var i = 0; i < attributeList.length; i++) {
        let pair = attributeList[i].split(':');
        let property = pair[0].toLowerCase().replaceAll(' ', '');
        if (property.includes('stamina')) {
            score.stamina += parseFloat(pair[1]) * balance / 10;
            continue;
        }
        if (property.includes('bhp')) {
            score.bhp += (parseFloat(pair[1]) / 10) * balance;
            continue;
        }
        if (property.includes('cc')) {
            score.cc += (parseFloat(pair[1]) / 100) * balance;
            continue;
        }

        if (property.includes('speed')) {
            score.speed += (parseFloat(pair[1]) / 10) * balance;
            continue;
        }

        if (property.includes('length')) {
            score.tracks += parseFloat(pair[1]) * balance;
            continue;
        }
    }
};

const getRankData = (plataPower) => {
    if (plataPower == 0)
        return { imgPath: '/plata-power/level0.jpeg', title: 'Gear up with Plata NFTs to increase your Power', level: 0 };
    if (plataPower < 99)
        return { imgPath: '/plata-power/level1.jpeg', title: 'Welcome to the Team', level: 1 };
    if (plataPower < 499)
        return { imgPath: '/plata-power/level2.jpeg', title: 'Rider', level: 2 };
    if (plataPower < 999)
        return { imgPath: '/plata-power/level3.jpeg', title: 'Speedster', level: 3 };
    if (plataPower < 4999)
        return { imgPath: '/plata-power/level4.jpeg', title: 'Pilot', level: 4 };
    if (plataPower < 9999)
        return { imgPath: '/plata-power/level5.jpeg', title: 'Professional Driver', level: 5 };
    if (plataPower < 24999)
        return { imgPath: '/plata-power/level6.jpeg', title: 'Ghost Rider', level: 6 };

    return { imgPath: '/plata-power/level7.jpeg', title: 'Plata Champion', level: 7 };
};

const getPlataPowerESDTS = (tickerData, score) => {
    switch (tickerData.identifier) {
        case 'PLATA-9ba6c3':
            score.plata += 100;
            score.balance += parseFloat((tickerData.balance / 100_000_000)?.toFixed(3));
            break;
    }
};

const updateScoresWithDriversData = (drivers, score) => {
    for (var i = 0; i < drivers.length; i++) {
        for (var j = 0; j < drivers[i].metadata.attributes.length; j++) {
            let attribute = drivers[i].metadata.attributes[j];
            let property = attribute.trait_type.toLowerCase();
            let value = attribute.value;
            if (property.includes('stamina')) {
                let val = parseFloat(value) / 10;
                score.stamina += val;
                continue;
            }
            if (property.includes('bhp')) {
                let val = parseFloat(value) / 10;
                score.bhp += val;
                continue;
            }
            if (property.includes('(cc)')) {
                let val = parseFloat(value) / 100;
                score.cc += val;
                continue;
            }

            if (property.includes('speed')) {
                let val = parseFloat(value) / 10;
                score.speed += val;
                continue;
            }

            if (property.includes('length')) {
                let val = parseFloat(value);
                score.tracks += val;
                continue;
            }
        }
    }
}

const updateScoresWithStakingData = (staked, score) => {
    for (var i = 0; i < staked.stakedNFTs.length; i++) {
        let stakedNft = staked.stakedNFTs[i];
        if (_250PowerTickers.includes(stakedNft.tokenIdentifier)) {
            score.special += 250;
            // score.army += parseInt(nftData.balance);
            return;
        }
        //XDRIVER-2ce741 si RACING-fc7ad4
        switch (stakedNft.tokenIdentifier) {
            case 'PLATAXMAS-874bd3':
            case 'CYBERPLATA-101cb3':
                score.army += 1;
                break;

            case 'PLATA-716c04':
            case 'GCCMMXXI-12efe5':
            case 'DRIVER-58558e':
            case 'BSKDRIVER-3a1600':
            case 'ESDTRUSH-4515bf':
            case 'PLATAXCIA-4ea660':
                score.emoon += 100;
                score.army += parseInt(stakedNft.quantity);
                break;

            case 'PLTPILOT-f3ac90':
            case 'PLTRIDER-f3ac90':
            case 'PLTAIDRV-f3ac90':
            case 'PLTSTART-f3ac90':
                score.army += parseInt(stakedNft.quantity);
                break;

            case 'RACING-fc7ad4': //Year:2021; Origin:UK; Engine(cc): 4000; Type: V8; Power(bhp): 528; TopSpeed(km/h): 314;
            case 'PLTCAR001-704035':
            case 'PLTCAR002-704035':
            case 'PLTCAR003-704035':
            case 'PLTCAR004-704035':
            case 'PLTCAR005-704035':
            case 'PLTCAR006-704035':
            case 'PLTCAR007-704035':
            case 'PLTCAR008-704035':
            case 'PLTCAR009-704035':
            case 'PLTCAR010-704035':
            case 'PLTCAR011-704035':
            case 'PLTCAR012-704035':
            case 'PLTCAR013-704035':
            case 'PLTCAR014-704035':
            case 'PLTCAR015-704035':
            case 'PLTCAR016-704035':
            case 'PLTCAR017-704035':
            case 'PLTCAR018-704035':
            case 'PLTCAR019-704035':
            case 'PLTCAR020-704035':
            case 'PLTCAR021-704035':
            case 'PLTCAR022-704035':
            case 'PLTCAR023-704035':
            case 'PLTCAR024-704035':
            case 'PLTCAR025-81e54b':
            case 'PLTCAR026-81e54b':
            case 'PLTCAR027-81e54b':
            case 'PLTCAR028-81e54b':
            case 'PLTCAR029-81e54b':
            case 'PLTCAR030-81e54b':
            case 'PLTCAR031-81e54b':
            case 'PLTCAR032-81e54b':
            case 'PLTCAR033-81e54b':
            case 'PLTCAR034-81e54b':
            case 'PLTCAR035-81e54b':
            case 'PLTCAR036-81e54b':
            case 'PLTCAR037-81e54b':
            case 'PLTCAR038-81e54b':
            case 'PLTCAR039-81e54b':
            case 'PLTCAR040-81e54b':
            case 'PLTCAR041-81e54b':
            case 'PLTCAR042-81e54b':
            case 'PLTCAR043-81e54b':
            case 'PLTCAR044-81e54b':
            case 'PLTCAR045-81e54b':
            case 'PLTCAR046-81e54b':
            case 'PLTCAR047-81e54b':
            case 'PLTCAR048-81e54b':
            case 'PLTCAR049-81e54b':
            case 'PLTCAR050-e8a825':
            case 'RACING-c0396f':
            case 'RACING-fbe2cc':
            case 'RACING-61cd12':
            case 'V8PLATA-e11632': {
                let attr = attributeMapping.filter(x => x.tokenIdentifier == stakedNft.tokenIdentifier);
                if (attr.length > 0) {
                    getPlataPowerByAttributes(attr[0].attributes, score, parseInt(stakedNft.quantity));
                }
                score.army += parseInt(stakedNft.quantity);
                break;
            }
            case 'XDRIVER-2ce741': {
                let attr = attributeMapping.filter(x => x.tokenIdentifier == stakedNft.tokenIdentifier);
                if (attr.length > 0) {
                    getPlataPowerByAttributes(attr[0].attributes, score, parseInt(stakedNft.quantity));
                }

                score.army += parseInt(stakedNft.quantity);
                score.emoon += 100;
                break;
            }
            case 'ALLTEAM-374106': {
                let attr = attributeMapping.filter(x => x.tokenIdentifier == stakedNft.tokenIdentifier);
                if (attr.length > 0) {
                    getPlataPowerByAttributes(attr[0].attributes, score, parseInt(stakedNft.quantity));
                }
                score.army += parseInt(stakedNft.quantity);
                break;
            }
            default:
                break;
        }
    }
}

export const getPlataPower = computePlataPower;

const attributeMapping = [{ tokenIdentifier: "XDRIVER-2ce741", attributes: "metadata:bafyreiapya3wok3jb2ieldriavafwkdxtcqdpx3sabbtzue32b54pjyj6q/metadata.json" },
{ tokenIdentifier: "RACING-fc7ad4", attributes: "PLATA GT3-16" },
{ tokenIdentifier: "PLTCAR001-704035", attributes: "Collection:PlataCars; No:#001; Year:2012; Origin:Japan/USA; Engine:Hybrid; TopSpeed(km/h):335;" },
{ tokenIdentifier: "PLTCAR002-704035", attributes: "Collection:PlataCars; No:#002; Year:2013; Origin:UK; Engine(cc):5935; Type:V12; TopSpeed(km/h):290;" },
{ tokenIdentifier: "PLTCAR003-704035", attributes: "Collection:PlataCars; No:#003; Year:1976; Origin:UK; Engine(cc):2279; Type:Straight-Four; TopSpeed(km/h):185;" },
{ tokenIdentifier: "PLTCAR004-704035", attributes: "Collection:PlataCars; No:#004; Year:2005; Origin:France; Engine(cc):7993; Type:W16; TopSpeed(km/h):407;" },
{ tokenIdentifier: "PLTCAR005-704035", attributes: "Collection:PlataCars; No:#005; Year:2009; Origin:Italy; Engine(cc):4499; Type:V8; TopSpeed(km/h):325;" },
{ tokenIdentifier: "PLTCAR006-704035", attributes: "Collection:PlataCars; No:#006; Year:1958; Origin:USA; Engine(cc):5981; Type:V8; TopSpeed(km/h):187;" },
{ tokenIdentifier: "PLTCAR007-704035", attributes: "Collection:PlataCars; No:#007; Year:2018; Origin:UK/Austria; Engine:Electric; TopSpeed(km/h):200;" },
{ tokenIdentifier: "PLTCAR008-704035", attributes: "Collection:PlataCars; No:#008; Year:1970; Origin:Germany; Engine(cc):2195; Type:Flat-Six; TopSpeed(km/h):232;" },
{ tokenIdentifier: "PLTCAR009-704035", attributes: "Collection:PlataCars; No:#009; Year:1959; Origin:Italy; Engine(cc):2953; Type:V12; TopSpeed(km/h):233;" },
{ tokenIdentifier: "PLTCAR010-704035", attributes: "Collection:PlataCars; No:#010; Year:1967; Origin:USA; Engine(cc):4727; Type:V8; TopSpeed(km/h):180;" },
{ tokenIdentifier: "PLTCAR011-704035", attributes: "Collection:PlataCars; No:#011; Year:1971; Origin:UK; Engine(cc):2286; Type:Straight-Four; TopSpeed(km/h):109;" },
{ tokenIdentifier: "PLTCAR012-704035", attributes: "Collection:PlataCars; No:#012; Year:2006; Origin:Italy; Engine(cc):3195; Type:V6; TopSpeed(km/h):232;" },
{ tokenIdentifier: "PLTCAR013-704035", attributes: "Collection:PlataCars; No:#013; Year:1938; Origin:France; Engine(cc):2998; Type:Straight-Six; TopSpeed(km/h):153;" },
{ tokenIdentifier: "PLTCAR014-704035", attributes: "Collection:PlataCars; No:#014; Year:2007; Origin:UK; Engine(cc):6750; Type:V12; TopSpeed(km/h):249;" },
{ tokenIdentifier: "PLTCAR015-704035", attributes: "Collection:PlataCars; No:#015; Year:1970; Origin:UK; Engine(cc):1599; Type:Straight-Four; TopSpeed(km/h):182;" },
{ tokenIdentifier: "PLTCAR016-704035", attributes: "Collection:PlataCars; No:#016; Year:1934; Origin:Germany; Engine(cc):5018; Type:Straight-Eight; TopSpeed(km/h):164;" },
{ tokenIdentifier: "PLTCAR017-704035", attributes: "Collection:PlataCars; No:#017; Year:1957; Origin:Sweden; Engine(cc):1583; Type:Straight-Four; TopSpeed(km/h):153;" },
{ tokenIdentifier: "PLTCAR018-704035", attributes: "Collection:PlataCars; No:#018; Year:1972; Origin:USA; Engine(cc):6588; Type:V8; TopSpeed(km/h):193;" },
{ tokenIdentifier: "PLTCAR019-704035", attributes: "Collection:PlataCars; No:#019; Year:1998; Origin:UK; Engine(cc):4398; Type:V8; TopSpeed(km/h):241;" },
{ tokenIdentifier: "PLTCAR020-704035", attributes: "Collection:PlataCars; No:#020; Year:1953; Origin:UK; Engine(cc):1991; Type:Straight-Four; TopSpeed(km/h):107;" },
{ tokenIdentifier: "PLTCAR021-704035", attributes: "Collection:PlataCars; No:#021; Year:1964; Origin:France; Engine(cc):1108; Type:Straight-Four; TopSpeed(km/h):171;" },
{ tokenIdentifier: "PLTCAR022-704035", attributes: "Collection:PlataCars; No:#022; Year:1949; Origin:Italy; Engine(cc):569; Type:Straight-Four; TopSpeed(km/h):97;" },
{ tokenIdentifier: "PLTCAR023-704035", attributes: "Collection:PlataCars; No:#023; Year:1938; Origin:France; Engine(cc):1991; Type:Straight-Four; TopSpeed(km/h):153;" },
{ tokenIdentifier: "PLTCAR024-704035", attributes: "Collection:PlataCars; No:#024; Year:1935; Origin:USA; Engine(cc):3408; Type:Straight-Six; TopSpeed(km/h):121;" },
{ tokenIdentifier: "PLTCAR025-81e54b", attributes: "Collection:PlataCars; No:#025; Year:1931; Origin:France; Engine(cc):4972; Type:Straight-Eight; TopSpeed(km/h):110;" },
{ tokenIdentifier: "PLTCAR026-81e54b", attributes: "Collection:PlataCars; No:#026; Year:1994; Origin:Italy; Engine(cc):3495.5; Type:V8; TopSpeed(km/h):295;" },
{ tokenIdentifier: "PLTCAR027-81e54b", attributes: "Collection:PlataCars; No:#026; Year:1977; Origin:UK; Engine(cc):5340; Type:V8; TopSpeed(km/h):270;" },
{ tokenIdentifier: "PLTCAR028-81e54b", attributes: "Collection:PlataCars; No:#027; Year:1998; Origin:Germany; Engine(cc):1984; Type:Straight-Four; TopSpeed(km/h):185;" },
{ tokenIdentifier: "PLTCAR029-81e54b", attributes: "Collection:PlataCars; No:#029; Year:1949; Origin:UK; Engine(cc):1172; Type:Straight-Four; TopSpeed(km/h):97;" },
{ tokenIdentifier: "PLTCAR030-81e54b", attributes: "Collection:PlataCars; No:#030; Year:1963; Origin:Germany; Engine(cc):6332; Type:V8; TopSpeed(km/h):209;" },
{ tokenIdentifier: "PLTCAR031-81e54b", attributes: "Collection:PlataCars; No:#031; Year:2006; Origin:Germany; Engine(cc):5204; Type:V10; TopSpeed(km/h):315;" },
{ tokenIdentifier: "PLTCAR032-81e54b", attributes: "Collection:PlataCars; No:#032; Year:2006; Origin:Sweden; Engine(cc):4719; Type:V8; TopSpeed(km/h):402;" },
{ tokenIdentifier: "PLTCAR033-81e54b", attributes: "Collection:PlataCars; No:#033; Year:1996; Origin:UK; Engine(cc):1796; Type:Straight-Four; TopSpeed(km/h):200;" },
{ tokenIdentifier: "PLTCAR034-81e54b", attributes: "Collection:PlataCars; No:#034; Year:1994; Origin:USA; Engine(cc):7886; Type:V10; TopSpeed(km/h):180;" },
{ tokenIdentifier: "PLTCAR035-81e54b", attributes: "Collection:PlataCars; No:#035; Year:2000; Origin:Japan; Engine(cc):1994; Type:Flat-Four; TopSpeed(km/h):220;" },
{ tokenIdentifier: "PLTCAR036-81e54b", attributes: "Collection:PlataCars; No:#036; Year:1999; Origin:Germany; Engine(cc):1781; Type:Straight-Four; TopSpeed(km/h):222;" },
{ tokenIdentifier: "PLTCAR037-81e54b", attributes: "Collection:PlataCars; No:#037; Year:1987; Origin:Italy; Engine(cc):2936; Type:V8; TopSpeed(km/h):324;" },
{ tokenIdentifier: "PLTCAR038-81e54b", attributes: "Collection:PlataCars; No:#038; Year:1980; Origin:UK; Engine(cc):1715; Type:Four-Cylinder; TopSpeed(km/h):185;" },
{ tokenIdentifier: "PLTCAR039-81e54b", attributes: "Collection:PlataCars; No:#039; Year:1965; Origin:USA/UK; Engine(cc):6997; Type:V8; TopSpeed(km/h):264;" },
{ tokenIdentifier: "PLTCAR040-81e54b", attributes: "Collection:PlataCars; No:#040; Year:1970; Origin:USA; Engine(cc):6276; Type:V8; TopSpeed(km/h):183;" },
{ tokenIdentifier: "PLTCAR041-81e54b", attributes: "Collection:PlataCars; No:#041; Year:1963; Origin:Italy; Engine(cc):3692; Type:Straight-Six; TopSpeed(km/h):233;" },
{ tokenIdentifier: "PLTCAR042-81e54b", attributes: "Collection:PlataCars; No:#042; Year:1966; Origin:UK; Engine(cc):1998; Type:Straight-Six; TopSpeed(km/h):180;" },
{ tokenIdentifier: "PLTCAR043-81e54b", attributes: "Collection:PlataCars; No:#043; Year:1955; Origin:France; Engine(cc):2175; Type:OHV-in-line-four; TopSpeed(km/h):171;" },
{ tokenIdentifier: "PLTCAR044-81e54b", attributes: "Collection:PlataCars; No:#044; Year:1955; Origin:Germany; Engine(cc):3168; Type:V8; TopSpeed(km/h):169;" },
{ tokenIdentifier: "PLTCAR045-81e54b", attributes: "Collection:PlataCars; No:#045; Year:1959; Origin:UK; Engine(cc):848; Type:Straight-Four; TopSpeed(km/h):116;" },
{ tokenIdentifier: "PLTCAR046-81e54b", attributes: "Collection:PlataCars; No:#046; Year:1954; Origin:Italy; Engine(cc):2494; Type:Straight-Six; TopSpeed(km/h):290;" },
{ tokenIdentifier: "PLTCAR047-81e54b", attributes: "Collection:PlataCars; No:#047; Year:1953; Origin:USA; Engine(cc):3859; Type:Straight-Six; TopSpeed(km/h):140;" },
{ tokenIdentifier: "PLTCAR048-81e54b", attributes: "Collection:PlataCars; No:#048; Year:1941; Origin:USA; Engine(cc):2199; Type:Straight-Four; TopSpeed(km/h):97;" },
{ tokenIdentifier: "PLTCAR049-81e54b", attributes: "Collection:PlataCars; No:#049; Year:1934; Origin:UK; Engine(cc):2700; Type:Straight-Six; TopSpeed(km/h):121;" },
{ tokenIdentifier: "PLTCAR050-e8a825", attributes: "Collection:PlataCars; No:#050; Year:1900; Origin:Germany; Engine(cc):1140; Type:Single-Cylinder; TopSpeed(km/h):35;" },
{ tokenIdentifier: "RACING-c0396f", attributes: "Origin:Germany;Power(bhp):450;Engine(cc):2981;TopSpeed(km/):332;description:911" },
{ tokenIdentifier: "RACING-fbe2cc", attributes: "description:Plata E30 DTM;tags:Elrond,PLATA,Racing,E30;Origin:Germany;Power(bhp):320;Engine(cc):2302;TopSpeed(km/h):281;Weight(kg):1025" },
{ tokenIdentifier: "RACING-61cd12", attributes: "Origin:USA;Power(bhp):700;Engine(cc):5000;TopSpeed(km/h):290;tags:PLATA,Racing,ELROND,Cars,Car,GT500;description:Plata GT500" },
{ tokenIdentifier: "V8PLATA-e11632", attributes: "Power(bhp):610;Engine(cc):5200;TopSpeed(km/h):355;Stamina:10;Length(km):100;tags:PLATA,Racing,ELROND,Cars;description:Plata Network V8 Collection Racing Car" },
]