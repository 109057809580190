import { dAppName } from 'config';
import withPageTitle from './components/PageTitle';

import Connect from './pages/UnlockPage';

import NFT from './pages/NFT';
import Dashboard from './pages/Dash';

// import Whitelist from 'pages/Whitelist';

export const routeNames = {
  home: '/',
  // Token: '/token',
  NFT: '/nft',

  // Power: '/power',
  Wallet: '/wallet',
  dashboard: '/dashboard',
  connect: '/connect',
  ledger: '/ledger',
  walletconnect: '/walletconnect',
  // whitelist: '/whitelist'
};

const routes: Array<any> = [
  // {
  //   path: routeNames.home,
  //   title: 'Dashboard',
  //   component: Dashboard
  // },
  // {
  //   path: routeNames.Token,
  //   title: 'Token',
  //   component: Token
  // },
  {
    path: routeNames.NFT,
    title: 'NFT',
    component: NFT
  },
  // {
  //   path: routeNames.Power,
  //   title: 'Power',
  //   component: Power
  // },
  // {
  //   path: routeNames.Wallet,
  //   title: 'Wallet',
  //   component: Wallet
  // },
  {
    path: routeNames.connect,
    title: 'Connect',
    component: Connect
  },
  {
    path: routeNames.dashboard,
    title: 'Dashboard',
    component: Dashboard,
    authenticatedRoute: true
  },
  // {
  //   path: routeNames.transaction,
  //   title: 'Transaction',
  //   component: Transaction
  // },
  // {
  //   path: routeNames.whitelist,
  //   title: 'Whitelist',
  //   component: Whitelist
  // }
];

const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} | ${dAppName}`
    : `${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});

export default mappedRoutes;
