import { Address, AddressValue, BooleanValue } from "@multiversx/sdk-core/out";
import { fetchQueryHex } from "./scRequests";

export const getLockedStakingData = (scAddress, userAddress, poolName, poolCapacity, minStakeAmount, maxStakeAmount, apr, lockTimePeriodDays) => {
    // let address = new Address("erd14kujelh4ewpp80a8ljp2mwkx2edj7mpej5hnnrfxnrjx8pg0l9gq8zav6d");
    let address = new Address(userAddress);
    let totalStakedByUser = 0, totalEarnedByUser = 0, unstakeTime = 0, totalStaked = 0, canUserClaim = false, canUserStake = false;
    return Promise.all([
        fetchQueryHex("getTotalStakedPerAddress", [new AddressValue(address)], scAddress),
        fetchQueryHex("totalEarned", [new AddressValue(address), new BooleanValue(false)], scAddress),
        fetchQueryHex("getFirstUnstakeTime", [new AddressValue(address)], scAddress),
        fetchQueryHex("getTotalStaked", [], scAddress),
    ]).then(
        ([totalStakedByUserHex, totalEarnedByUserHex, firstUserUnstakeTimeHex, totalStakedHex]) => {

            totalStakedByUser = parseHex(totalStakedByUserHex);
            totalEarnedByUser = parseHex(totalEarnedByUserHex);
            unstakeTime = parseInt(firstUserUnstakeTimeHex.substr(2), 16) * 1000;//parseHex(firstUserUnstakeTimeHex) * 1000;
            totalStaked = parseHex(totalStakedHex);

            canUserStake = (poolCapacity - totalStaked > minStakeAmount);
            canUserClaim = unstakeTime < Date.now() && totalEarnedByUser > 0;
            if (totalEarnedByUser > totalStakedByUser){
                totalEarnedByUser -= totalStakedByUser;
                totalEarnedByUser = parseFloat(totalEarnedByUser?.toFixed(3));
            } else {
                totalEarnedByUser = 0;
            }
            return {
                poolName: poolName,
                totalStakedByUser: totalStakedByUser,
                totalEarned: totalEarnedByUser,
                unstakeTime: unstakeTime,
                totalStaked: totalStaked,
                canUserStake: canUserStake,
                canUserClaim: canUserClaim,
                poolCapacity: poolCapacity,
                minStakeAmount: minStakeAmount,
                maxStakeAmount: maxStakeAmount,
                APR: apr,
                scAddress: scAddress,
                lockTimePeriodDays: lockTimePeriodDays
            };
        });
}

const parseHex = (val) => {
    if (val === "-1")
        return 0;
    let parsed = parseInt(val, 16);
    parsed /= 100_000_000;
    return parseFloat(parsed?.toFixed(3));
}