/* eslint-disable */
import React, { useState } from "react";
import {
  useGetIsLoggedIn,
  useGetAccountInfo,
} from "@multiversx/sdk-dapp/hooks";
import { routeNames } from "routes";

import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

import { getWalletNFTs } from "../../helpers/asyncRequests";
import WalletItem from "../../components/Wallet/walletItem";

const Wallet = ({ details, parentSetter, walletInventoryData }) => {
  const { address } = useGetAccountInfo();
  const [walletNfts, setWalletNfts] = useState([]);
  const [loadedNfts, setLoadedNfts] = useState([]);

  const [whitelisted] = useState([
    "BSKDRIVER-3a1600",
    "GCCMMXXI-12efe5",
    "CYBERPLATA-101cb3",
    "PLATAXCIA-4ea660",
    "PLATA-715c04",
    "DRIVER-58558e",
    "XDRIVER-2ce741",
    "PLTAIDRV-f3ac90",
    "PLTAIDRV-8aec9a",
    "PLTRIDER-f3ac90",
    "PLTRIDER-8aec9a",
    "PLTSTART-f3ac90",
    "PLTSTART-8aec9a",
    "RACING-61cd12",
    "RACING-c0396f",
    "RACING-fbe2cc",
    "PLTPILOT-8aec9a",
    "PLTPILOT-f3ac90",
    "PLATAVOX-c763d7",
    "RACING-fc7ad4",
    "PLATA-716c04",
  ]);

  const [listSize] = useState(19);
  let selectable = false;
  if (details != undefined && details.selectable == true) {
    selectable = true;
  }
  const [limit, setLimit] = useState(20);

  const copyToClipboard = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(address);

    toast("Address copied to clipboard", {
      position: "bottom-right",
      toastId: "copy-address",
      transition: Slide,
      theme: "dark",
      //className: 'foo-bar'
    });
  };

  const showMoreItems = () => {
    //console.log( walletNfts )
    //console.log( loadedNfts )

    let size = loadedNfts.length;
    let array = loadedNfts;
    walletNfts.forEach((item, i) => {
      if (i >= size && i <= size + listSize) {
        array.push(item);
      }
    });

    setLoadedNfts(array);

    let newLimit = limit + limit;
    setLimit(newLimit);
  };

  const transformData = (nftItem) => {
    let tokenIdentifierSplit = nftItem.tokenIdentifier.split("-");
    return {
      attributes: Buffer.from(nftItem.attributes || "", "base64").toString(),
      balance: parseInt(nftItem.balance),
      creator: nftItem.creator,
      owner: address,
      name: nftItem.name, //name
      nonce: nftItem.nonce,
      royalties: "${parseInt(nftItem.royalties) / 100} %",
      fullTokenIdentifier: nftItem.tokenIdentifier,
      //tokenIdentifier: '${tokenIdentifierSplit[0]}-${tokenIdentifierSplit[1]}',
      tokenIdentifier: tokenIdentifierSplit[0] + "-" + tokenIdentifierSplit[1],
      uris: nftItem.uris.map((u) => Buffer.from(u, "base64").toString()),
    };
  };

  const loadWallet = (address) => {
    let parsed = [];
    let walletNfts = getWalletNFTs({ address }).then(({ data }) => {
      if (data != undefined) {
        let items = Object.entries(data.data.esdts).map((x) => x[1]);
        for (var i = 0; i < items.length; i++) {
          if (items[i].uris !== undefined) parsed.push(transformData(items[i]));
        }
        return parsed;
      }
      return parsed;
    });
    return walletNfts;
  };

  const isLoggedIn = useGetIsLoggedIn();
  React.useEffect(() => {
    if (!isLoggedIn) {
      window.location.href = "/connect";
    } else {
      //console.log(address);
      if (walletInventoryData != undefined) {
        let parsed = [];
        let items = Object.entries(walletInventoryData).map((x) => x[1]);
        for (var i = 0; i < items.length; i++) {
          if (items[i].uris?.length > 0) parsed.push(transformData(items[i]));
        }

        let filtered = [];
        parsed.forEach((item, i) => {
          if (
            details == undefined ||
            !details.filteredOnly ||
            (details.filteredOnly == true &&
              whitelisted.includes(item.tokenIdentifier))
          ) {
            filtered.push(item);
          }
        });

        setWalletNfts(filtered);

        let loaded = [];
        filtered.forEach((item, i) => {
          if (i <= listSize) {
            loaded.push(item);
          }
        });
        setLoadedNfts(loaded);
      } else {
        loadWallet(address).then((val) => {
          let filtered = [];
          val.forEach((item, i) => {
            if (
              details == undefined ||
              !details.filteredOnly ||
              (details.filteredOnly == true &&
                whitelisted.includes(item.tokenIdentifier))
            ) {
              filtered.push(item);
            }
          });

          setWalletNfts(filtered);

          let loaded = [];
          filtered.forEach((item, i) => {
            if (i <= listSize) {
              loaded.push(item);
            }
          });
          setLoadedNfts(loaded);
        });
      }
    }
  }, []);

  return (
    <div className="container">
      {details != undefined && details.source != "component" && (
        <div className="row mt-3 mb-5">
          <div className="col-12">
            <div className="intro text-center">
              <div className="intro-content text-center">
                <h3 className="mt-3 mb-3 d-block">Elrond NFT Wallet Content</h3>

                <div
                  className="mt-5 mb-0 copy-box"
                  data-tip={address}
                  onClick={(e) => copyToClipboard(e)}
                >
                  {address.substring(0, 10)}
                  {address.length >= 16 && "..."}
                  {address.substring(address.length - 6, address.length)}
                </div>

                <ReactTooltip />
                <ToastContainer transition={Slide} />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row mt-2 w-100 items">
        {loadedNfts?.length == 0 && (
          <div className="col-12 text-center mt-1 mb-4">
            {" "}
            You don't have any NFTs that can be staked{" "}
          </div>
        )}

        {loadedNfts &&
          loadedNfts?.length > 0 &&
          loadedNfts.map((v, i) => {
            return (
              <WalletItem
                key={i}
                v={v}
                selectable={selectable}
                properties={details}
                parentStateSetter={parentSetter}
              />
            );
          })}
      </div>

      {walletNfts.length > 0 && walletNfts?.length > loadedNfts?.length && (
        <div className="col-12 text-center cursor-pointer mt-5">
          <a
            className="mybtn btn-bordered-white pt-2 pb-2"
            onClick={showMoreItems}
          >
            Load more
          </a>
        </div>
      )}
    </div>
  );
};

export default Wallet;
