/* eslint-disable */
import { logout } from '@multiversx/sdk-dapp/utils';
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const handleLogout = () => {
    logout(`${window.location.origin}/`);
    document.getElementById('toggleHamburger').click();
  };

  const toggleMenu = () => {
     document.getElementById('toggleHamburger').click();
  };

  const isLoggedIn = useGetIsLoggedIn();


  return (
  
    <header className="header">
        <div className="container">
            <div className="row">

                <div className="logo-wrapper col-auto col-auto">
                        <a className="header_logo" href="/"> 
                            <img id="logo" src="./logo-plata-green.png" />
                        </a>	
                </div>

                    <input id="toggle" type="checkbox" />
                    <label id="toggleHamburger" className="toggle-container" htmlFor="toggle">
                        <span className="button button-toggle">
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                        </span>
                    </label>


                <nav className="navbar nav-wrapper col">
                    <div className="container-fluid justify-content-sm-start justify-content-md-end">
                        <ul id="nav" className="nab navbar-nav navbar-right text-400">
                        
                            {/* <li  className={window.location.pathname == '/' ? 'menu-item current' : 'menu-item '} >  <Link to="/" onClick={()=>{ toggleMenu(); }} > Home </Link> </li> */}
                            {/* <li  className={window.location.pathname == '/token' ? 'menu-item current' : 'menu-item '} >  <Link to="/token" onClick={()=>{ toggleMenu(); }} > Token </Link> </li> */}
                            <li  className={window.location.pathname == '/nft' ? 'menu-item current' : 'menu-item '} >  <Link to="/nft" onClick={()=>{ toggleMenu(); }} > NFT </Link> </li>
                            {/* <li  className={window.location.pathname == '/power' ? 'menu-item current' : 'menu-item '} >  <Link to="/power" onClick={()=>{ toggleMenu(); }} > Power </Link> </li> */}
                            
                            { isLoggedIn && <li  className={window.location.pathname == '/dashboard' ? 'menu-item current' : 'menu-item '} >  <Link to="/dashboard" onClick={()=>{ toggleMenu(); }} > Dashboard </Link> </li> }
                            
                            <li className="menu-item">
                                { !isLoggedIn && <Link to="/connect" className="mybtn btn-bordered-white pt-2 pb-2" onClick={()=>{ toggleMenu(); }}> Connect </Link> }
                                { isLoggedIn && <a onClick={()=>{ handleLogout(); }} className="mybtn btn-bordered-white pt-2 pb-2" > Disconnect </a> }
                                
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="dimmer"></div>

            </div>
        </div>
    </header>
    
  );
};

export default Navbar;

/*

<BsNavbar className='bg-white border-bottom px-4 py-3'>
      <div className='container-fluid'>
        <Link
          className='d-flex align-items-center navbar-brand mr-0'
          to={isLoggedIn ? routeNames.dashboard : routeNames.home}
        >
          <ElrondLogo className='elrond-logo' />
          <span className='dapp-name text-muted'>{dAppName}</span>
        </Link>

        <Nav className='ml-auto'>
          {isLoggedIn && (
            <NavItem>
              <a href={routeNames.home} onClick={handleLogout}>
                Close
              </a>
            </NavItem>
          )}
        </Nav>
      </div>
    </BsNavbar>
*/
