/* eslint-disable */
import React, { Component, useState } from 'react';

import Wallet from '../../Wallet';

import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';

import { decodeAddress, int2hex, str2hex } from 'helpers/encoding';
import { nftStaking } from 'config';

const StakeNfts = ({ stakePool, esdtData }) => {
    console.log(esdtData);
    const [tab, setTab] = useState('');
    const { address } = useGetAccountInfo();
    const [Selected, setSelected] = useState([]);
    const addToSelected = (item) => {

        let array = Selected
        if (!checkIfSelected(item.fullTokenIdentifier)) {
            array.push(item)
        } else {
            array = array.filter(function (nft) {
                return item.fullTokenIdentifier !== nft.fullTokenIdentifier
            })
        }

        setSelected([])
        setSelected(array)

        console.log(array)

    }
    const checkIfSelected = (identifier) => {

        let array = Selected
        let found = false
        array.forEach((item) => {
            if (item.fullTokenIdentifier == identifier) {
                found = true
            }
        })

        return found

    }

    const claimRewards = async () => {

        let baseGasLimit = 35_000_000;
        let gasLimit = baseGasLimit + (stakePool.stakedNFTs?.length * 4_000_000);

        let transaction = {
            receiver: nftStaking.scAddress,
            gasLimit: gasLimit,
            data: "claimRewards",
            value: "0"
        };
        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: transaction,
            transactionsDisplayInfo: {
                processingMessage: 'Processing claim rewards transaction',
                errorMessage: 'An error has occured during claiming rewards',
                successMessage: 'Transaction successful',
                transactionDuration: 10000,
            }
        });
    }

    const stakeNFTs = async () => {
        console.log(Selected);

        if (Selected.length > 0) {
            let baseGasLimit = 15_000_000;
            baseGasLimit += (stakePool.stakedNFTs?.length * 900_000);
            const gasPerNft = 5_000_000;
            let transaction = {
                receiver: address,
                gasLimit: baseGasLimit,
                data: "",
                value: "0"
            };

            if (Selected.length > 1) {
                // multiEsdtNFTTransfer
                transaction.data = `MultiESDTNFTTransfer@${decodeAddress(nftStaking.scAddress)}@${int2hex(Selected.length)}`;
                for (var i = 0; i < Selected.length; i++) {
                    transaction.data +=
                        `@${str2hex(Selected[i].tokenIdentifier)}@${int2hex(Selected[i].nonce)}@${int2hex(Selected[i].quantity || 1)}`;
                    transaction.gasLimit += gasPerNft;
                }
                transaction.data += `@${str2hex("stake")}`;

            } else {
                //singleNFTTransfer
                transaction.data = `ESDTNFTTransfer@${str2hex(Selected[0].tokenIdentifier)}@${int2hex(Selected[0].nonce)}@${int2hex(Selected[0].quantity || 1)}@${decodeAddress(nftStaking.scAddress)}@${str2hex("stake")}`;
                transaction.gasLimit += (gasPerNft * 2);
            }

            const { sessionId, error } = await sendTransactions({
                transactions: [transaction],
                transactionsDisplayInfo: {
                    processingMessage: 'Processing NFT Stake transaction',
                    errorMessage: 'An error has occured during staking',
                    successMessage: 'Transaction successful',
                    transactionDuration: 10000,
                }
            });
            ///

            ///

        } else {
            toast('Please make sure to select at least 1 NFT', {
                position: 'bottom-right',
                toastId: 'copy-address',
                transition: Slide,
                theme: 'dark'
                //className: 'foo-bar'
            });
        }

    }

    React.useEffect(() => {

        //console.log(stakePool);
        //console.log(esdtData);

    }, []);

    return (
        <div className=''>

            <div className="row text nftstostake" >

                <Wallet details={{ source: "component", filteredOnly: true, selectable: true }} parentSetter={addToSelected} walletInventoryData={esdtData} />

            </div>

            <div className="row text mt-5 text-center" >
                {stakePool.isDisabled ? <p style={{ color: "red" }}>Maintenance</p> :
                    <a className="mybtn btn-black pt-2 pb-2 mr-2 d-inline w-auto" onClick={() => { stakeNFTs() }} > Add NFTs to Stake </a>}

                {!stakePool.isDisabled && stakePool.claimableRewards > 0 &&
                    <a className="mybtn btn-black pt-2 pb-2 mr-2 d-inline w-auto" onClick={() => { claimRewards() }} > Claim rewards:  {stakePool.claimableRewards} $PLATA</a>
                }


            </div>

        </div>
    );
};

export default StakeNfts;
