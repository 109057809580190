import React from 'react';
import { useGetIsLoggedIn, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { routeNames } from 'routes';
import { useLocation } from 'react-router-dom';
import {
  ExtensionLoginButton,
  WebWalletLoginButton,
  LedgerLoginButton,
  WalletConnectLoginButton,
  OperaWalletLoginButton
} from '@multiversx/sdk-dapp/UI';

export const UnlockRoute: () => JSX.Element = () => {
  const { address } = useGetAccountInfo();
  const { search } = useLocation();
  const isLoggedIn = useGetIsLoggedIn();
  React.useEffect(() => {
    if(isLoggedIn){
      if(search){
        window.location.href = "/" + search.replace("?from=", "");
      }else{
        window.location.href = "/dashboard";
      }
    }
  }, [isLoggedIn]);

  return (
    <div className="container connect-page" data-testid="unlockPage">
      <div className="col-12 text-center">
        <h2 className="mb-4 font-weight-bold">Connect wallet</h2>
      </div>

      <div className="row mt-5">
        <div className="col-12 col-md-3 mt-2 mb-2">
          <div className="card text-center bg-black">
            <div className="card-body">
              <img
                className="avatar-lg"
                src="https://www.erdnft.com/img/maiar-32x32.png"
                alt=""
              />
              <h4 className="mb-0 mt-4 font-weight-bold">xPortal</h4>
              <p>Connect using the xPortal Wallet</p>

              <WalletConnectLoginButton
                callbackRoute="/dashboard"
                loginButtonText={"Connect"}
                isWalletConnectV2={true}
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-2 mb-2">
          <div className="card text-center bg-black">
            <div className="card-body">
              <img
                className="avatar-lg"
                src="https://www.erdnft.com/img/maiar-32x32.png"
                alt=""
              />
              <h4 className="mb-0 mt-4 font-weight-bold">DeFi Extension</h4>
              <p>Connect using the DeFi Browser Extension</p>

              <ExtensionLoginButton
                callbackRoute="/dashboard"
                loginButtonText={"Connect"}
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-2 mb-2">
          <div className="card text-center bg-black">
            <div className="card-body">
              <img
                className="avatar-lg"
                src="https://www.erdnft.com/img/maiar-32x32.png"
                alt=""
              />
              <h4 className="mb-0 mt-4 font-weight-bold">Web Wallet</h4>
              <p>Connect using the MultiversX Web Wallet</p>

              <WebWalletLoginButton
                callbackRoute="/dashboard"
                loginButtonText={"Connect"}
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-2 mb-2">
          <div className="card text-center bg-black">
            <div className="card-body">
              <img
                className="avatar-lg"
                src="https://www.erdnft.com/img/maiar-32x32.png"
                alt=""
              />
              <h4 className="mb-0 mt-4 font-weight-bold">Ledger Wallet</h4>
              <p>Connect using the Ledger Wallet</p>

              <LedgerLoginButton
                loginButtonText={"Connect"}
                callbackRoute="/dashboard"
                className={"test-class_name"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockRoute;

/*

<div className='home d-flex flex-fill align-items-center'>
  <div className='m-auto' data-testid='unlockPage'>
    <div className='card my-4 text-center'>
      <div className='card-body py-4 px-2 px-sm-2 mx-lg-4'>

        <h4 className='mb-4'>Login</h4>
        <p className='mb-4'>pick a login method</p>

        <ExtensionLoginButton
          callbackRoute={routeNames.dashboard}
          loginButtonText={'Extension'}
        />
        <WebWalletLoginButton
          callbackRoute={routeNames.dashboard}
          loginButtonText={'Web wallet'}
        />
        <LedgerLoginButton
          loginButtonText={'Ledger'}
          callbackRoute={routeNames.dashboard}
          className={'test-class_name'}
        />
        <WalletConnectLoginButton
          callbackRoute={routeNames.dashboard}
          loginButtonText={'Maiar'}
        />
      </div>
    </div>
  </div>
</div>

*/