/* eslint-disable */
import React, { Component, useState } from "react";
import Wallet from "../../Wallet";
import StakeNftComp from "./StakeNfts";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";
import {
  bigUint2hex,
  int2hex,
  padBeforeString,
  str2hex,
  strNestedEncode,
} from "helpers/encoding";
import { nftStaking } from "config";
import { sendTransactions } from "@multiversx/sdk-dapp/services";

import { BigUIntValue, U32Value } from "@multiversx/sdk-core/out";
import { useGetAccountInfo } from "@multiversx/sdk-dapp/hooks";

const Staking = ({ stakePool, esdtData }) => {
  const [tab, setTab] = useState("");
  const { address } = useGetAccountInfo();

  const [Selected, setSelected] = useState([]);
  const addToSelected = (item) => {
    let array = Selected;
    if (!checkIfSelected(item.fullTokenIdentifier)) {
      array.push(item);
    } else {
      array = array.filter(function (nft) {
        return item.fullTokenIdentifier !== nft.fullTokenIdentifier;
      });
    }

    setSelected([]);
    setSelected(array);
  };
  const checkIfSelected = (identifier) => {
    let array = Selected;
    let found = false;
    array.forEach((item) => {
      if (item.fullTokenIdentifier == identifier) {
        found = true;
      }
    });

    return found;
  };

  const claimRewards = async () => {
    let baseGasLimit = 35_000_000;
    let gasLimit = baseGasLimit + stakePool.stakedNFTs?.length * 4_000_000;

    let transaction = {
      receiver: nftStaking.scAddress,
      gasLimit: gasLimit,
      data: "claimRewards",
      value: "0",
    };
    const { sessionId /*, error*/ } = await sendTransactions({
      transactions: transaction,
      transactionsDisplayInfo: {
        processingMessage: "Processing claim rewards transaction",
        errorMessage: "An error has occured during claiming rewards",
        successMessage: "Transaction successful",
        transactionDuration: 10000,
      },
    });
  };

  const unstake = async () => {
    const baseGasLimit = 65_000_000;
    const gasPerNft = 12_000_000;
    if (Selected.length > 0) {
      let transaction = {
        receiver: nftStaking.scAddress,
        gasLimit: baseGasLimit,
        data: "",
        value: "0",
      };
      transaction.data = `unstake`;
      for (var i = 0; i < Selected.length; i++) {
        let token = strNestedEncode(Selected[i].tokenIdentifier);
        let qty = bigUint2hex(
          new BigUIntValue(new U32Value(Selected[i].quantity || 1).value)
        );
        let nonce = padBeforeString(int2hex(Selected[i].nonce), 16);
        transaction.data += `@${token}${nonce}${qty}`;
        transaction.gasLimit += gasPerNft;
      }
      if (transaction.gasLimit > 600_000_000) {
        transaction.gasLimit = 600_000_000;
      }
      // console.log(transaction.data);
      // return;
      const { sessionId /*, error*/ } = await sendTransactions({
        transactions: transaction,
        transactionsDisplayInfo: {
          processingMessage: "Processing NFT Unstake transaction",
          errorMessage: "An error has occured during unstaking",
          successMessage: "Transaction successful",
          transactionDuration: 10000,
        },
      });
    } else {
      toast("Please make sure to select at least 1 NFT", {
        position: "bottom-right",
        toastId: "copy-address",
        transition: Slide,
        theme: "dark",
        //className: 'foo-bar'
      });
    }
  };

  React.useEffect(() => {
    //console.log(stakePool);
  }, []);

  return (
    <div className="">
      <div className="col-12 text-center mt-3 ">
        <Tabs
          defaultActiveKey="staked"
          id="nav-tab"
          className="w-auto mt-4 mb-3"
        >
          <Tab eventKey="staked" title="Staked">
            <div className="row mt-2">
              {stakePool.stakedNFTs?.length == 0 && (
                <div className="col-12 text-center mt-1 mb-4">
                  {" "}
                  You don't have any NFTs stake. Starting adding them{" "}
                </div>
              )}

              {stakePool.stakedNFTs &&
                stakePool.stakedNFTs?.length > 0 &&
                stakePool.stakedNFTs.map((data, i) => {
                  data.fullTokenIdentifier =
                    data.tokenIdentifier + "-" + data.nonce;
                  return (
                    <div className="col-6 col-md-3 p-1 mt-2" key={i}>
                      <div className="stats-box row m-0 stats-box-small">
                        <div className="col-12 text-left">
                          <a
                            className="cursor-pointer "
                            onClick={() => {
                              addToSelected(data);
                            }}
                          >
                            <input type="checkbox" />
                          </a>
                        </div>
                        <div className="col-12 mt-1">
                          <img
                            src={`https://api.elrond.com/nfts/${
                              data.tokenIdentifier
                            }-${int2hex(data.nonce)}/thumbnail`}
                          />
                          <div className="text-center text-bold text-700 ">
                            {" "}
                            {data.quantity} x {data.tokenIdentifier}-
                            {int2hex(data.nonce)}{" "}
                          </div>
                          {/* <div className="heading5" > <b>Rewards: {data.dailyReward} $PLATA</b>/day </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            {stakePool.stakedNFTs && stakePool.stakedNFTs?.length > 0 && (
              <div className="row mt-5 mb-4">
                <div className="col-12">
                  {stakePool.isDisabled ? (
                    <p style={{ color: "red" }}>Maintenance</p>
                  ) : (
                    <>
                      <a
                        className="mybtn btn-black pt-2 pb-2 mr-2 d-inline"
                        onClick={() => {
                          unstake();
                        }}
                      >
                        {" "}
                        Unstake selected{" "}
                      </a>
                    </>
                  )}
                  {!stakePool.isDisabled && stakePool.claimableRewards > 0 && (
                    <a
                      className="mybtn btn-black pt-2 pb-2 mr-2 d-inline w-auto"
                      onClick={() => {
                        claimRewards();
                      }}
                    >
                      {" "}
                      Claim rewards: {stakePool.claimableRewards} $PLATA{" "}
                    </a>
                  )}
                </div>
              </div>
            )}
          </Tab>

          <Tab eventKey="addtostake" title="Add to stake">
            <StakeNftComp stakePool={stakePool} esdtData={esdtData} />
          </Tab>
        </Tabs>
      </div>

      <ToastContainer transition={Slide} />
    </div>
  );
};

export default Staking;
