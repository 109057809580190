import React, {useState} from "react";
import { useGetIsLoggedIn } from '@multiversx/sdk-dapp/hooks';

export const PowerSmallSize = ({esdtData}) => {
    const [data, setData] = useState({});
    let dummy = {
        imgPath: "", title: "Pilot",
        army: 6,
        stamina: 2000,
        bhp: 133,
        cc: 52.83,
        speed: 28.1,
        tracks: 60,
        gcc: 0,
        plata: 100,
        special: 0,
        emoon: 0,
        total: 2520,
        level: 3,
    };
    const isLoggedIn = useGetIsLoggedIn();

    React.useEffect(() => {
        
        if (!isLoggedIn) {
            window.location.href = '/connect';
        }else{
            setData(esdtData);
        }
                
    }, []);

    const formatFloatValue = (val) => {
        return parseFloat((val ?? 0).toFixed());
    };

    return <div className="container" >
        <div className="row mb-5" >

            <div className="col-12 col-md-5" >
                <div className="col-12 col-md-8 offset-md-2" >
                    <img src={"./power/level" + data.level + ".jpeg"}  alt="image" className="axonometric-right" />
                </div>
            </div>

            <div className="col-12 col-md-7 row mt-5 mt-md-0 pt-3 pt-md-0" >

                <div className="col-12 mb-2" >
                    <div className="row" >
                        <div className="col-12 col-md-6" >
                            <h1 className="text-left text-bold text-700 mt-2 heading3 font-primary"> {data.title} </h1>
                        </div>
                        <div className="col-12 col-md-6" >
                            <h1 className="text-left text-md-right text-bold text-700 mt-2 heading3 font-primary"> <span className="heading3" > Power: </span> { formatFloatValue( data.total ) } </h1>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-3 p-1" >
                    <div className="stats-box row m-0 stats-box-small" >
                        <div className="col-12" >
                            <img src="./icons/army.png" className="" alt="image" />
                        </div>
                        <div className="col-12 mt-2" >
                            <div className="text-center text-bold text-700 "> NFT Army </div>
                            <div className="heading4" > {formatFloatValue(data.army)} </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-3 p-1" >
                    <div className="stats-box row m-0 stats-box-small" >
                        <div className="col-12" >
                            <img src="./icons/stamina.png" className="" alt="image" />
                        </div>
                        <div className="col-12 mt-2" >
                            <div className="text-center text-bold text-700 "> Stamina </div>
                            <div className="heading4" > {formatFloatValue(data.stamina)} </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-3 p-1" >
                    <div className="stats-box row m-0 stats-box-small" alt="image" >
                        <div className="col-12" >
                            <img src="./icons/hp.png" className="" />
                        </div>
                        <div className="col-12 mt-2" >
                            <div className="text-center text-bold text-700"> Horse Power </div>
                            <div className="heading4" > {formatFloatValue(data.bhp)} </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-3 p-1" >
                    <div className="stats-box row m-0 stats-box-small" >
                        <div className="col-12" >
                            <img src="./icons/engine.png" className="" alt="image" />
                        </div>
                        <div className="col-12 mt-2" >
                            <div className="text-center text-bold text-700"> Engine </div>
                            <div className="heading4" > {formatFloatValue(data.cc)} </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-3 p-1" >
                    <div className="stats-box row m-0 stats-box-small" alt="image" >
                        <div className="col-12" >
                            <img src="./icons/speed.png" className="" alt="image" />
                        </div>
                        <div className="col-12 mt-2" >
                            <div className="text-center text-bold text-700"> Speed </div>
                            <div className="heading4" > {formatFloatValue(data.speed)} </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-3 p-1" >
                    <div className="stats-box row m-0 stats-box-small" >
                        <div className="col-12" >
                            <img src="./icons/track.png" className="" alt="image" />
                        </div>
                        <div className="col-12 mt-2" >
                            <div className="text-center text-bold text-700"> Tracks </div>
                            <div className="heading4" > {formatFloatValue(data.tracks)} </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-3 p-1" >
                    <div className="stats-box row m-0 stats-box-small" >
                        <div className="col-12" >
                            <img src="./icons/bonus.png" className="" alt="image" />
                        </div>
                        <div className="col-12 mt-2" >
                            <div className="text-center text-bold text-700"> GCC Bonus </div>
                            <div className="heading4" > {formatFloatValue(data.gcc)} </div>
                        </div>
                    </div>
                </div>

                <div className="col-6 col-md-3 p-1" >
                    <div className="stats-box row m-0 stats-box-small" >
                        <div className="col-12" >
                            <img src="./icons/bonus2.png" className="" alt="image" />
                        </div>
                        <div className="col-12 mt-2" >
                            <div className="text-center text-bold text-700"> PLATA Bonus </div>
                            <div className="heading4" > {formatFloatValue(data.plata)} </div>
                        </div>
                    </div>
                </div>

                <div className="col-12" >
                    <div className="row" >
                        <div className="col-12" >
                            <h1 className="text-left text-md-right text-bold text-700 mt-3 heading3 font-primary"> <span className="heading5" > PLATA Balance: </span> { parseFloat( data.balance )?.toFixed() } </h1>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>;
}