import React, {useState} from 'react';
import { PowerRegularSize } from "./regular";
import { PowerSmallSize } from "./small";
import { getDriversNFTs, getWalletNFTs } from "helpers/asyncRequests";
import { useGetIsLoggedIn, useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

import { routeNames } from "routes";
import { getPlataPower } from "helpers/plataPower";
import { getNftStakingData } from 'helpers/nftStaking';

const Power = ({isRegular}) => {
    const [powerScores, setPowerScores] = useState({});
    const [mounted, setMounted] = useState(false);
    
    const { address } = useGetAccountInfo();
    const loggedIn = useGetIsLoggedIn();
    // const address = 'erd1g3ufcfdlnpamunzpaa4kutg3z7y9vtuqm3twpkh57ye2ppycgenseekdcl';

    // const address = "erd1sc8w58xys9t66gaa9plehdaqtp0rn9hhqxkh2gswpkdmk5q4wj5q769jt6";

    //useEffect - load wallet -> calc & set
    React.useEffect(() => {
        if (mounted)
            return;
        if (!loggedIn && isRegular){
            setPowerScores({
                army: 0,
                stamina: 0,
                bhp: 0,
                cc: 0,
                speed: 0,
                tracks: 0,
                gcc: 0,
                plata: 0,
                special: 0,
                emoon: 0,
        
                balance: 0,
                imgPath: "", title: "Pilot",
                level: 0
            });
            setMounted(true);
            return;
        }
        if (!loggedIn){
            window.location.href = routeNames.home;
            return;
        }
        Promise.all([
            getNftStakingData(address),
            getWalletNFTs({address}),
            getDriversNFTs({address})
        ])
        .then(([stakingData, esdts, drivers]) => {
            setPowerScores(getPlataPower(esdts.data.data.esdts, stakingData, drivers.data));
            setMounted(true);
        });
    }, []);
    return mounted && (isRegular
        ? <PowerRegularSize esdtData={powerScores} />
        : <PowerSmallSize esdtData={powerScores} />);
}

export default Power;