export const decimals = 4;
export const denomination = 18;
export const gasPerDataByte = 1500;
export const timeout = 10000; // 10 sec

export const walletConnectBridgeAddresses: string[] = [
  'https://bridge.walletconnect.org'
];
export const walletConnectBridge: string =
  walletConnectBridgeAddresses[
  Math.floor(Math.random() * walletConnectBridgeAddresses.length)
  ];
export const walletConnectV2ProjectId = '36e7dacbc9c196b5f88ec42c454c1559';

export const walletConnectDeepLink =
  'https://maiar.page.link/?apn=com.multiversx.maiar.wallet&isi=1519405832&ibi=com.multiversx.maiar.wallet&link=https://maiar.com/';

export const dAppName = 'Plata Network';

// export const network = {
//   id: 'devnet',
//   name: 'Devnet',
//   egldLabel: 'xEGLD',
//   walletAddress: 'https://devnet-wallet.multiversx.com',
//   apiAddress: 'https://devnet-api.multiversx.com',
//   gatewayAddress: 'https://devnet-gateway.multiversx.com',
//   explorerAddress: 'http://devnet-explorer.multiversx.com',
//   graphQlAddress: 'https://devnet-exchange-graph.multiversx.com/graphql'
// };


export const network = {
  id: 'mainnet',
  name: 'Mainnet',
  egldLabel: 'EGLD',
  walletAddress: 'https://wallet.multiversx.com',
  apiAddress: 'https://api.multiversx.com',
  gatewayAddress: 'https://gateway.multiversx.com',
  // gatewayAddress: 'https://elrond-mainnet-api.bwarelabs.com/de2470ad-6103-4a1f-b036-3bae9ced392f',
  explorerAddress: 'http://explorer.multiversx.com',
  graphQlAddress: 'https://exchange-graph.multiversx.com/graphql'
};


export const lockedStakingPools = [
  {
    poolName: "Locked Staking Pool #1",
    scAddress: "erd1qqqqqqqqqqqqqpgqwl5w0rp740uwyq24hx2f702tqsdrwa9ggr9qanf5rf",
    poolCapacity: 20_000_000,
    minStakeAmount: 100_000,
    maxStakeAmount: 1_000_000,
    lockTimePeriodDays: 365,
    apr: 29
  },
  {
    poolName: "Locked Staking Pool #2",
    scAddress: "erd1qqqqqqqqqqqqqpgqkwt9d457frlkq4t4j3awsezc8c8meg8hyl5sp4td7z",
    poolCapacity: 20_000_000,
    minStakeAmount: 100_000,
    maxStakeAmount: 1_000_000,
    lockTimePeriodDays: 365,
    apr: 22
  }
]

export const nftStaking = {
  poolName: "NFT Staking",
  scAddress: "erd1qqqqqqqqqqqqqpgqkq0hqqx770tmk5kjj7l07fnn6kzcymypyl5s7xcj2s"
}

export const whitelist = {
  scAddress: "erd1qqqqqqqqqqqqqpgqynurn9ymuch9356hl9vkd3xhfvm46wu0yl5smrm3rw",
  egldPrice: 6.14,
  egldPayloadPrice: 6_140_000_000_000_000_000,
  plataPrice: 194000,
  plataPayloadPrice: 194000_0000_0000,
  startTime: 1645203600000
}

export const saleContractAddress = "erd1qqqqqqqqqqqqqpgq0g4pxn2tzj3h6uf9dhn5z36rz80n2pzqyl5s3skep7";
export const carPacksSwapAddress = "erd1qqqqqqqqqqqqqpgq4evlul8j0c9x0dh6yzvrsweeapvgdnesyl5s84ncz9";

export const PLATA_TOKEN_TICKER = "PLATA-9ba6c3";