/* eslint-disable */
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

const ItemMedia = ({ v, maxHeight }) => {
    
    const [isSendNftVisible, setIsSendNftVisible] = useState({status: false});
    
    //console.log(v)
    const request = ( MediaURL ) =>{
        
        return axios({ 
                method: 'get',
                url: MediaURL,

            })
            .then(resp => {
                //console.log(resp.headers['content-type'])
                let blob = resp.data;
                //console.log(blob)
                
                let contentType = resp.headers['content-type'];
                //let contentData = 'data:image/png;base64,'+ blob
                let contentData = MediaURL;
            
                setIsSendNftVisible({ contentData: contentData, contentType: contentType, status: true });
            })
            .catch(error => {
                
                //console.log(error);
                //console.log(MediaURL);
                //request( MediaURL )
                setIsSendNftVisible({ status: false });

            }); //end axios 
        
    };
    
    const loadMedia = () => {
        //console.log( isSendNftVisible )
        //console.log(v)
        let payload = v;
        
        let MediaURL = '';
        if( payload.Image ){
            //MediaURL = payload.Image.uri;
            MediaURL = { contentData: payload.Image.uri, contentType: 'image', status: true }
            setIsSendNftVisible(MediaURL)
        }else if( payload.url ){
            //MediaURL = payload.url;
            MediaURL = { contentData: payload.url, contentType: 'image', status: true };
            setIsSendNftVisible(MediaURL)
        }else{
            //MediaURL = ;
            request( v.uris[0] );
        }
            
        //return null;
        //setTimeout( function(){ setIsSendNftVisible(MediaURL); return MediaURL }, 1000);
        return MediaURL
        

    };
    
    React.useEffect(() => {
            

        if( maxHeight == undefined ){
            maxHeight = 205;
        }
        //console.log( maxHeight )
        loadMedia();
        
        
    }, []);
    
    return (
        <>
            { isSendNftVisible.status == false && maxHeight > 200 &&
                <div className='media-preloader pt-2' > <div className='mt-5 loader'></div> </div>
            } 
            { isSendNftVisible.status == false && maxHeight < 200 &&
                <div className='media-preloader preloader-sm pt-2' > <div className='loader'></div> </div>
            } 
            { isSendNftVisible.status && isSendNftVisible.contentType.includes( 'image' ) &&
                <div className='media-wrapper' style={{width: maxHeight, height: maxHeight, }} >
                    <img className='card-img-top ' src={isSendNftVisible.contentData} alt='image' />
                </div>
            } 
            { isSendNftVisible.status && isSendNftVisible.contentType.includes( 'video' ) &&
                <center>
                <video width={maxHeight} height={maxHeight} controls autoPlay={true} loop muted>
                    <source src={isSendNftVisible.contentData} type={isSendNftVisible.contentType} />
                    Your browser does not support HTML video.
                </video>
             </center>
            } 
        </>
    );
};

export default ItemMedia;