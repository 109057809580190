import React, { useState } from "react";

export const PowerRegularSize = ({ esdtData }) => {
    const [data, setData] = useState({});



    React.useEffect(() => {

        // setData(dummy);
        setData(esdtData);

    }, []);

    const formatFloatValue = (val) => {
        return parseFloat((val ?? 0).toFixed());
    };

    return (
        <div className='container mt-5'>

            <div className="row mt-5 text">


                <div className="col-12 col-md-6 offset-md-3" >

                    <img src={"./power/level" + data.level + ".jpeg"} className="col-12 col-md-8 offset-md-2 axonometric-right" />

                </div>

                <div className="col-12" >

                    <h1 className="text-center text-bold text-700 mt-5 heading1 font-primary"> {data.title} </h1>
                    <h1 className="text-center text-bold text-700 mt-5 heading3 font-primary"> {formatFloatValue(data.total)} </h1>

                </div>

                <div className="col-12 row pt-5" >

                    <h1 className="col-12 text-center text-bold text-700 mt-5 mb-5 heading4 font-primary"> Stats </h1>

                    <div className="col-6 col-md-3 p-3" >
                        <div className="stats-box row m-0" >
                            <div className="col-12" >
                                <img src="./icons/army.png" className="" />
                            </div>
                            <div className="col-12 mt-3" >
                                <div className="text-center text-bold text-700 "> NFT Army </div>
                                <div className="heading2" > {formatFloatValue(data.army)} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 p-3" >
                        <div className="stats-box row m-0" >
                            <div className="col-12" >
                                <img src="./icons/stamina.png" className="" />
                            </div>
                            <div className="col-12 mt-3" >
                                <div className="text-center text-bold text-700 "> Stamina </div>
                                <div className="heading2" > {formatFloatValue(data.stamina)} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 p-3" >
                        <div className="stats-box row m-0" >
                            <div className="col-12" >
                                <img src="./icons/hp.png" className="" />
                            </div>
                            <div className="col-12 mt-3" >
                                <div className="text-center text-bold text-700"> Horse Power </div>
                                <div className="heading2" > {formatFloatValue(data.bhp)} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 p-3" >
                        <div className="stats-box row m-0" >
                            <div className="col-12" >
                                <img src="./icons/engine.png" className="" />
                            </div>
                            <div className="col-12 mt-3" >
                                <div className="text-center text-bold text-700"> Engine </div>
                                <div className="heading2" > {formatFloatValue(data.cc)} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 p-3" >
                        <div className="stats-box row m-0" >
                            <div className="col-12" >
                                <img src="./icons/speed.png" className="" />
                            </div>
                            <div className="col-12 mt-3" >
                                <div className="text-center text-bold text-700"> Speed </div>
                                <div className="heading2" > {formatFloatValue(data.speed)} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 p-3" >
                        <div className="stats-box row m-0" >
                            <div className="col-12" >
                                <img src="./icons/track.png" className="" />
                            </div>
                            <div className="col-12 mt-3" >
                                <div className="text-center text-bold text-700"> Tracks </div>
                                <div className="heading2" > {formatFloatValue(data.tracks)} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 p-3" >
                        <div className="stats-box row m-0" >
                            <div className="col-12" >
                                <img src="./icons/bonus.png" className="" />
                            </div>
                            <div className="col-12 mt-3" >
                                <div className="text-center text-bold text-700"> GCC Bonus </div>
                                <div className="heading2" > {formatFloatValue(data.gcc)} </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 p-3" >
                        <div className="stats-box row m-0" >
                            <div className="col-12" >
                                <img src="./icons/bonus2.png" className="" />
                            </div>
                            <div className="col-12 mt-3" >
                                <div className="text-center text-bold text-700"> PLATA Bonus </div>
                                <div className="heading2" > {formatFloatValue(data.plata)} </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-12 col-md-8 offset-md-2 text-justify heading6 mt-5" >
                    The Plata Power functionality is based on the NFTs published by us, and our collaborations. The number of NFTs that you hold and their attributes combined are calculated to give your the total Power.
                    <br /><br />
                    As your Power increases, your ranking picture and title are automatically adjusted. The Power is integrated in the Plata Power Play-to-Earn game, offering you the possibility to earn $PLATA, and unlock different circuit zones as you gain experience in the game.
                    <br /><br />
                    The NFT Army represents the total number of NFTs that you hold, while Stamina, Engine, Speed and the others are calculated based on the attributes of the NFTs that you hold. The $PLATA and $GCC bonus are activted if you hold these tokens in your wallet.
                    <br /><br />
                    This functinality is developed together with ERDNFT, and it will be constantly updated as the project evolves, to further enhance your experience.
                    <br /><br />
                    <div className="d-flex justify-content-center">
                        <a href="https://power.plata.network" target="_blank" className="mybtn btn-bordered-white pt-2 pb-2 ml-2" > Play-to-earn </a>
                    </div>
                </div>

            </div>

        </div>
    );
}