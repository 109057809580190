import { Address, BigUIntValue, ArgSerializer } from '@multiversx/sdk-core/out';

const serializer = new ArgSerializer();
export const str2hex = (val: string) => {
    return Buffer.from(val).toString("hex");
}

export const int2hex = (val: number) => {
    let str = val.toString(16);
    if (str.length % 2 === 0)
        return str;
    return "0" + str;
}

export const decodeAddress = (erdAddress: string) => {
    return new Address(erdAddress).hex();
}

export const bigUint2hex = (val: BigUIntValue) => {
    let hexVal = serializer.valuesToString([val]);
    let bytes = hexVal.argumentsString.length / 2;
    let hexBytes = padBeforeString(int2hex(bytes), 8);
    let res = `${hexBytes}${hexVal.argumentsString}`;
    console.log(res);
    return res;
}

export const padBeforeString = (val: string, expectedLength: number) => {
    let co = val;
    while(co.length < expectedLength){
        co = "0" + co;
    }
    return co;
}

export const strNestedEncode = (str: string) => {
    let hexVal = str2hex(str);
    let bytes = hexVal.length / 2;
    let hexBytes = padBeforeString(int2hex(bytes), 8);
    let res = `${hexBytes}${hexVal}`;
    return res;
}