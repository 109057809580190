/* eslint-disable */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ItemMedia from './walletItemMedia';

import ReactTooltip from 'react-tooltip';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import BuyButton from './buyButton';

const WalletInventoryItem = ({ parentStateSetter, v, properties, selectable }) => {
    const [isSendNftVisible, setIsSendNftVisible] = useState({ status: false });
    const [quantity, setQuantity] = useState(1);
    const [selected, setSelected] = useState(true);

    //console.log(v)
    //console.log( properties )    
    //console.log( v.clickable )

    const [ModalVisible, setModalVisible] = useState(false);
    const openModal = (val) => {
        //setModalVisible(false)
        let value = !ModalVisible;
        //console.log(value)
        setModalVisible(value);

    };

    const handleQuantityChange = (e) => {
        let data = e.target.value;
        let int = parseInt(data);
        // if (int < v.balance){
        //     data = "0";
        // }
        if (int > v.balance) {
            int = v.balance;
        }
        setQuantity(int)
        v.quantity = int;

        //console.log(quantity)
    }

    const check = (force = false) => {

        v.quantity = quantity

        //console.log(selected)
        if (selected == true) {
            setSelected(false)
        } else {
            setSelected(true)
        }

        parentStateSetter(v)

    }


    const checkForce = () => {

        v.quantity = quantity

        //console.log(selected)
        if (selected == true) {
            setSelected(false)
        } else {
            setSelected(true)
        }

        parentStateSetter(v)


    }

    return (
        <>
            <div className={v.status == 'unavailable' ? 'col-6 col-md-25 item unavailable' : 'col-6 col-md-25 item'}  >

                {selectable == true &&
                    <div className="row m-1" >
                        <div className="col-auto" >
                            <a className="cursor-pointer " onClick={() => { check() }}  >
                                {!selected && <input type="checkbox" onChange={() => { }} checked />}
                                {selected && <input type="checkbox" onChange={() => { }} />}
                            </a>
                        </div>
                        <div className="col text-right" >
                            {(v.balance > 1) && <input type="number" value={quantity} onChange={(e) => { handleQuantityChange(e) }} className="form-control quantity-selector-top pl-1 pr-1 mr-2" />}
                            {(v.balance > 1) && <a onClick={() => { checkForce() }} className="cursor-pointer d-inline position-absolute quantity-check" > <i className="fas fa-check-circle"></i>  </a>}
                        </div>
                    </div>
                }

                <div className='card p-3'>

                    <div className='image-over'>
                        {(properties == undefined || (properties != undefined && !properties.clickable)) && <ItemMedia v={v} maxHeight={205} />}

                        {(properties != undefined && properties.clickable == true) &&
                            <Link to={'/item/' + v.fullTokenIdentifier}>
                                <ItemMedia v={v} maxHeight={205} />
                            </Link>
                        }


                    </div>
                    {/* Card Caption */}
                    <div className='card-caption col-12 p-0'>
                        {/* Card Body */}
                        <div className='card-body pt-3'>
                            {(properties != undefined && properties.clickable == true) &&
                                <Link to={'/item/' + v.fullTokenIdentifier}>
                                    <h5 className='mb-0 font-weight-normal font-size-16'>{v.name}</h5>
                                </Link>
                            }
                            {(properties == undefined || (properties != undefined && !properties.clickable)) && <h5 className='mb-0 font-weight-normal font-size-16 text-left'>{v.name}</h5>}

                            <div className='seller d-flex align-items-center mt-2'>
                                {(properties != undefined && properties.clickable == true) &&
                                    <Link to={'/collection/' + v.tokenIdentifier} >
                                        <h6 className='ml-0 mb-0 font-weight-normal font-size-13 color-gray text-left' data-tip={v.tokenIdentifier} >
                                            {v.tokenIdentifier.substring(0, 15)}{v.tokenIdentifier.length >= 25 && '...'}{v.tokenIdentifier.substring(v.tokenIdentifier.length - 10, v.tokenIdentifier.length)}
                                        </h6>
                                    </Link>
                                }

                                {(properties == undefined || (properties != undefined && !properties.clickable)) &&
                                    <h6 className='ml-0 mb-0 font-weight-normal font-size-12 color-gray text-left' data-tip={v.tokenIdentifier} >
                                        {v.tokenIdentifier}
                                    </h6>
                                }

                            </div>

                            {(properties == undefined || (properties != undefined && !properties.clickable)) && properties.priceTags && properties?.supply > 0 &&
                                <>
                                    <div className='mt-1 mb-1'>
                                        {properties.supply} remaining
                                    </div>
                                    <div>
                                        {properties.priceTags.map(pt => <BuyButton priceTag={pt} />)}
                                    </div>
                                </>
                            }

                            {v.status == 'unavailable' && <div className='seller d-flex align-items-center mt-2'>Sold out</div>}

                            {/*
                            <a className='btn btn-bordered-white btn-smaller mt-3 ' onClick={openModal} data-toggle='modal' data-target={`#transfer-nft-${v.fullTokenIdentifier}`} >
                                <i className='icon-envelope mr-2' /> Send
                            </a>


                            <a className='btn btn-bordered-white btn-smaller mt-3 ' onClick={openModal} data-toggle='modal' data-target={`#transfer-nft-${v.fullTokenIdentifier}`} >
                                <i className='icon-envelope mr-2' /> Trade
                            </a>
                            
                            <Link className='btn btn-bordered-white btn-smaller mt-3 d-none' to={`/transfer-item?tokenIdentifier=${v.fullTokenIdentifier}`} >
                                <i className='icon-envelope mr-2' />Send
                            </Link>
                            */}

                            <ReactTooltip />
                            <ToastContainer transition={Slide} />


                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default WalletInventoryItem;