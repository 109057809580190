/* eslint-disable */
import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import {
  TransactionsToastList,
  SignTransactionsModals,
  NotificationModal
} from '@multiversx/sdk-dapp/UI';
import {
  DappProvider
} from '@multiversx/sdk-dapp/wrappers';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import Layout from 'components/Layout';
import { walletConnectV2ProjectId } from 'config';
import PageNotFound from 'pages/PageNotFound';
import routes from 'routes';

const App = () => {
  return (
    <Router>
      <DappProvider
        environment={EnvironmentsEnum.mainnet}
        customNetworkConfig={{
          name: 'customConfig',
          apiTimeout: 10000,
          walletConnectV2ProjectId
        }}
      >
        <Layout>
          <TransactionsToastList />
          <NotificationModal />
          <SignTransactionsModals className='custom-class-for-modals' />
          <Routes>
            {routes.map((route: any, index: number) => (
              <Route
                path={route.path}
                key={'route-key-' + index}
                element={<route.component />}
              />
            ))}
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </Layout>
      </DappProvider>
    </Router>
  );
};

export default App;
